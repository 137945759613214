import React, { useState, useEffect } from 'react';
import { Button, Box, Text, Select, Flex, Stack, Checkbox, Tooltip } from '@chakra-ui/react';
import API from '../api';
import Faq from './FAQ';
import ModalCheckList from './CheckList/ModalCheckList';
import { Icon } from '@chakra-ui/icons';
import { LoadingComponentNoWorker } from './LoadingComponentNoWorker';

const Subject = {
  math: 'math',
  rus: 'rus',
  eng: 'english',
  deu: 'deutsch',
  fr: 'francais',
  phys: 'physics',
  physSim: 'physics-sim',
  essay: 'essay',
};

let d = {'parts':
    {'3 Кинематика':
        {'Кинематика. Динамика':
            {'1 Координаты, анализ графиков':
                {'1 График v/t': 'jteavng7',
                  '2 График S/t': 'zxch5rcf',
                  '3a График x/t': 'jbdqysss',
                  '3b График x/t': 'aypgdssb',
                  '4 График Vx/t': 'gyhac3nr',
                  '5 График v/t': 'hebjxuax',
                  '8 График a/t': 'fbxndwnc'},
              '2 Равномерное движение, относительность движения':
                {'1 S/t График с выбором': 'gmycxnd9',
                  '2 x/t График макс скорость': 'ceqzmctv',
                  '5 x/t график путь': 'rctuzkp6'}},
          'sample':
            {'show_ups':
                {'Маятник': 'zq979zeg',
                }}
        },
      '5 Кинематика':
        {'Статика. Механические колебания и волны':
            {'1 Сила Архимеда, закон Паскаля':
                {'1 Давление в трубке': 'vw2xnaw7',}}

        },
      '14 Электродинамика':
        {'Электрическое поле. Законы постоянного тока':
            {'3 Электрическая ёмкость':
                {'1 Электрическая цепь': 'aw28kxpd',}}
        },
      '16 Электродинамика':
        {'Электромагнитные колебания и волны. Оптика':
            {'2 Линзы':
                {'1 Линза': 'fxcqdv8g'},
              '4 Закон преломления Снеллиуса':
                {'1 Преломление': 'w4zag62c',}}
        }
    },
}

const Lesson = {
  exercise: {
    math: 'Задание:',
    rus: 'Задание:',
    english: 'Корпус:',
    tag: 'Тег',
    francais: 'Корпус:',
    deutsch: 'Корпус:',
    physics: 'Задание:',
    'physics-sim': 'Тема:',
    essay: 'Язык',
  },
  chooseExercise: {
    math: 'Выберите задание',
    rus: 'Выберите задание',
    english: 'Выберите корпус',
    tag: 'Выберите тег',
    francais: 'Выберите корпус',
    deutsch: 'Выберите корпус',
    physics: 'Выберите задание',
    'physics-sim': 'Выберите тему:',
    essay: 'Выберите язык',
  },
  subNumber: {
    math: 'Шаблон:',
    english: 'Часть речи:',
    francais: 'Часть речи:',
    deutsch: 'Часть речи:',
    physics: 'Шаблон:',
    'physics-sim': 'Раздел:',
    essay: 'Задание',
  },
  chooseSubNumber: {
    math: 'Выберите шаблон',
    english: 'Выберите часть речи',
    francais: 'Выберите часть речи',
    deutsch: 'Выберите часть речи',
    physics: 'Выберите шаблон',
    'physics-sim': 'Выберите раздел:',
    essay: 'Выберите задание',
  },
  //задание(примеры), шаблон

};

const variableThemePhysSim = [
  '3 Кинематика',
  '5 Кинематика',
  '14 Электродинамика',
  '16 Электродинамика'

]

const variablePartPhysSim = {
  '3 Кинематика': [
    'Кинематика. Динамика',
  ],

  '5 Кинематика': [
    'Статика. Механические колебания и волны'
  ],

  '14 Электродинамика': [
    'Электрическое поле. Законы постоянного тока'
  ],

  '16 Электродинамика': [
    'Электромагнитные колебания и волны. Оптика'
  ]
}

const variableTaskPhysSim = {
  'Кинематика. Динамика': [
    '1 Координаты, анализ графиков',
    '2 Равномерное движение, относительность движения',
    'Примеры'
  ],

  'Статика. Механические колебания и волны': [
    '1 Сила Архимеда, закон Паскаля'
  ],

  'Электрическое поле. Законы постоянного тока': [
    '3 Электрическая ёмкость'
  ],

  'Электромагнитные колебания и волны. Оптика': [
    '2 Линзы',
    '4 Закон преломления Снеллиуса'
  ],
}

const variableTemplatePhysSim = {
  '1 Координаты, анализ графиков':[
    '1 График v/t',
    '2 График S/t',
    '3a График x/t',
    '3b График x/t',
    '4 График Vx/t',
    '5 График v/t',
    '8 График a/t',
  ],
  '2 Равномерное движение, относительность движения':[
    '1 S/t График с выбором',
    '2 x/t График макс скорость',
    '5 x/t график путь',
  ],
  'Примеры':[
    'Маятник',
  ],

  '1 Сила Архимеда, закон Паскаля': [
    '1 Давление в трубке'
  ],

  '3 Электрическая ёмкость': [
    '1 Электрическая цепь'
  ],

  '2 Линзы': [
    '1 Линза'
  ],

  '4 Закон преломления Снеллиуса': [
    '1 Преломление'
  ]
}

const variablePhysSim = {
  '1 График v/t': {id: 'jteavng7', link: ''},
  '2 График S/t': {id: 'zxch5rcf', link: ''},
  '3a График x/t': {id: 'jbdqysss', link: ''},
  '3b График x/t': {id: 'aypgdssb', link: ''},
  '4 График Vx/t': {id: 'gyhac3nr', link: ''},
  '5 График v/t': {id: 'hebjxuax', link: ''},
  '8 График a/t': {id: 'fbxndwnc', link: ''},
  '1 S/t График с выбором': {id: 'gmycxnd9', link: ''},
  '2 x/t График макс скорость': {id: 'ceqzmctv', link: ''},
  '5 x/t график путь': {id: 'rctuzkp6', link: ''},
  '1 Давление': {id: 'vw2xnaw7', link: ''},
  '2 Эл. Цепь': {id: 'aw28kxpd', link: ''},
  '3 маятник': {id: 'zq979zeg', link: ''},
  '4 линза': {id: 'fxcqdv8g', link: ''},
  '5 преломление': {id: 'w4zag62c', link: ''},
}




const variableGetTaskList = (sub) => {
  if (sub === Subject.rus)
    return API.getRusTasksList;
  else if (sub === Subject.math)
    return API.getMathTasksList;
  else if (sub === Subject.eng)
    return API.getEnglishTasksList;
  else if (sub === Subject.fr)
    return API.getFrancaisTasksList;
  else if (sub === Subject.phys)
    return API.getPhysicsTasksList;
  else if (sub === Subject.physSim)
    return variableThemePhysSim;
  else if (sub === Subject.essay)
    return API.getEssayTasksList;
  else
    return API.getDeutschTasksList;
};

const variableGetSubnumbers = (sub, task) => {
  console.log('subnumbers', sub, task);
  if (sub === Subject.math)
    return API.getMathSubnumbers;
  else if (sub === Subject.eng)
    return API.getEnglishSubNumbers;
  else if (sub === Subject.fr)
    return API.getFrancaisSubNumbers;
  else if (sub === Subject.phys)
    return API.getPhysicsSubnumbers;
  else if (sub === Subject.physSim)
    return variablePartPhysSim[task];
  else if (sub === Subject.essay)
    return API.getEssaySubNumbers;
  else
    return API.getDeutschSubNumbers;
};


const CheckWelcomeScreen = ({
                              subject,
                              setSubject,
                              task,
                              setTask,
                              onSubmit,
                              subnumber,
                              setSubnumber,
                              buttonText,
                              setSubTask,
                              isUniqueTaskEng,
                              setIsUniqueTaskEng,
                              isSearchByTags, setIsSearchByTags,
                              physSim, setPhysSim,
                            }) => {
  //мб стоит создать контекст для props`ов
  const [taskList, setTaskList] = useState([]);
  const [subnumbersList, setSubnumbersList] = useState([]);
  const [subTaskList, setSubTaskList] = useState([]); // для тегов если ищем по корпусам
  //или для тегов если ищем по корпусам

  const [taskSim, setTaskSim] = useState('');

  const [isFirstRenderDone, setIsFirstRenderDone] = useState(false);

  const [checkedItems, setCheckedItems] = React.useState([]);
  const [checkedSubItems, setCheckedSubItems] = React.useState([]);

  const [isLoadingPageBecauseNoWorker, setIsLoadingPageBecauseNoWorker] = React.useState(false);

  const getTaskListAgain = (lastTimeoutID) => {
    window.clearTimeout(lastTimeoutID);
    const getTasksList = variableGetTaskList(subject);
    getTasksList().then(resp => {
      setIsLoadingPageBecauseNoWorker(false);
      if (subject === Subject.phys || subject === Subject.math || subject === Subject.essay) {
        setTaskList(() => {
          return Object.keys(resp.body).map((key) => {return {[key]: resp.body[key]}}).sort((a, b) => {
            if (Object.values(a)[0].toLowerCase() < Object.values(b)[0].toLowerCase()) {
              return -1;
            }
            if (Object.values(a)[0].toLowerCase() > Object.values(b)[0].toLowerCase()) {
              return 1;
            }
            // a должно быть равным b
            return 0;
          });
        })
        setSubTaskList(() => []);
      }
      else {
        setTaskList(() => resp.data);
        setSubTaskList(prev => resp.dataTags);
      }
      setSubTask([]);
    }).catch(err => {
      if (err?.response?.status === 605) {
        let timeoutID = setTimeout(() => {
          getTaskListAgain(timeoutID);
        }, 10000);
      } else {
        setIsLoadingPageBecauseNoWorker(false);
      }
    });
  };

  const getSubnumbersAgain = (lastTimeoutID) => {
    window.clearTimeout(lastTimeoutID);
    const getSubnumbers = variableGetSubnumbers(subject);
    getSubnumbers().then(resp => {
      setIsLoadingPageBecauseNoWorker(false);
      setSubnumbersList(resp.data);
    }).catch(err => {
      if (err?.response?.status=== 605) {
        let timeoutID = setTimeout(() => {
          getTaskListAgain(timeoutID);
        }, 10000);
      } else {
        setIsLoadingPageBecauseNoWorker(false);
      }
    });
  };

  const handleSubjectChange = e => {
    setSubject(e.target.value);
    setTask('');
    setSubnumber('');
    setIsSearchByTags(false);
    setIsUniqueTaskEng(true);
    setSubTaskList([]);
    // setSubTask(subTaskList);
  };
  const handleSearchByCorpus = () => {
    setIsSearchByTags(false);
    setSubTask([]);
  };
  const handleSearchByTags = () => {
    setIsSearchByTags(true);
    setSubTask([]);
  };

  const handleUniqueTaskEng = () => {
    setIsUniqueTaskEng(prev => !prev);
  };

  const handleDisabled = () => {
    if (subject === 'physics-sim')
    {
      return (physSim === '' || physSim.length === 0)
    }
    return task.length === 0 || subnumber.length === 0;
  };

  useEffect(() => {
    const copyTaskList = taskList;
    setTaskList(subTaskList);
    setSubTaskList(copyTaskList);
  }, [isSearchByTags]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    setSubject('math');
    setTask('');
    setSubnumber('');
    setIsFirstRenderDone(true);
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (isFirstRenderDone) {
      const getTasksList = variableGetTaskList(subject);
      if (subject === Subject.physSim) {
        setTaskList(() => getTasksList);
        setSubnumbersList(variableGetSubnumbers(subject, task));
        setSubnumber([]);
      }
      else {
      getTasksList().then(resp => {
        if(subject === Subject.phys || subject === Subject.math || subject === Subject.essay) {
          setTaskList(() => {
            return Object.keys(resp.body).map((key) => {return {[key]: resp.body[key]}}).sort((a, b) => {
              if (Object.values(a)[0].toLowerCase() < Object.values(b)[0].toLowerCase()) {
                return -1;
              }
              if (Object.values(a)[0].toLowerCase() > Object.values(b)[0].toLowerCase()) {
                return 1;
              }
              // a должно быть равным b
              return 0;
            });
          })
          setSubTaskList(() => []);
          setSubTask([]);
        }
        else { //???? rus
          setTaskList(() => resp.data);
          setSubTaskList(prev => resp.dataTags);
          setSubTask([]);
        }
      }).catch(err => {
        if (err?.response?.status === 605) {
          setIsLoadingPageBecauseNoWorker(true);
          let timeoutID = setTimeout(() => {
            getTaskListAgain(timeoutID);
          }, 10000);
        }
      });
    }
    } else {
      API.getMathTasksList().then(resp => {
        setTaskList(() => {
          return Object.keys(resp.body).map((key) => {return {[key]: resp.body[key]}}).sort((a, b) => {
            if (Object.values(a)[0].toLowerCase() < Object.values(b)[0].toLowerCase()) {
              return -1;
            }
            if (Object.values(a)[0].toLowerCase() > Object.values(b)[0].toLowerCase()) {
              return 1;
            }
            // a должно быть равным b
            return 0;
          });
        })
      }).catch(err => {
        if (err?.response?.status === 605) {
          setIsLoadingPageBecauseNoWorker(true);
          let timeoutID = setTimeout(() => {
            getTaskListAgain(timeoutID);
          }, 10000);
        }
      });
    }
    if (subject === Subject.eng || subject === Subject.fr || subject === Subject.deu) { // unique, subtaskList, checked,
      const getSubnumbers = variableGetSubnumbers(subject);
      getSubnumbers().then(resp => {
        setSubnumbersList(resp.data);
      }).catch(err => {
        if (err?.response?.status === 605) {
          setIsLoadingPageBecauseNoWorker(true);
          let timeoutID = setTimeout(() => {
            getSubnumbersAgain(timeoutID);
          }, 10000);
        }
      });
    }
  }, [subject]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (task && (subject === Subject.math || subject === Subject.phys || subject === Subject.essay)) {
      const getSubnumbers = variableGetSubnumbers(subject);
      getSubnumbers({ task }).then(resp => {
        setSubnumbersList(resp.data);
      }).catch(err => {
        if (err?.response?.status === 605) {
          setIsLoadingPageBecauseNoWorker(true);
          let timeoutID = setTimeout(() => {
            getSubnumbersAgain(timeoutID);
          }, 10000);
        }
      });
    } // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [task, subject]); // delete subject?

  return (
    <>
      {isLoadingPageBecauseNoWorker ? <LoadingComponentNoWorker />
        :
        <Box width='400px' mr='auto' ml='auto'>
          <Select mb='40px' onChange={handleSubjectChange} value={subject}>
            <option value='math'>Математика</option>
            <option value='physics'>Физика</option>
            <option value='physics-sim'>Физика симуляция</option>
            <option value='rus'>Русский язык</option>
            <option value='essay'>Изложение</option>
            <option value='english'>Английский язык</option>
            <option value='deutsch'>Немецкий язык</option>
            <option value='francais'>Французский язык</option>
          </Select>
          {(subject === Subject.eng || subject === Subject.deu || subject === Subject.fr) && ( //foreignLanguage.find(subject)
            <>
              <Text fontWeight={'semibold'} mb={'15px'} mx={'auto'}>Выберите критерии подбора заданий</Text>
              <Stack justifyContent={'space-around'} mb={'15px'} direction={'row'}>
                <Checkbox
                  border={isSearchByTags ? '' : '0px'}
                  icon={
                    <Icon boxSize={5} viewBox='0 0 200 200'>
                      <path
                        fill='#1E80C1'
                        d='M 100, 100 m -75, 0 a 75,75 0 1,0 150,0 a 75,75 0 1,0 -150,0'
                      />
                    </Icon>
                  }
                  colorScheme={'white'}
                  size={'lg'}
                  isChecked={!isSearchByTags}
                  onChange={handleSearchByCorpus}
                >
                  Корпус
                </Checkbox>
                <Checkbox
                  border={isSearchByTags ? '0px' : ''}
                  icon={
                    <Icon boxSize={5} viewBox='0 0 200 200'>
                      <path
                        fill='#1E80C1'
                        d='M 100, 100 m -75, 0 a 75,75 0 1,0 150,0 a 75,75 0 1,0 -150,0'
                      />
                    </Icon>
                  }
                  colorScheme={'white'}
                  size={'lg'}
                  isChecked={isSearchByTags}
                  onChange={handleSearchByTags}
                >
                  Тег
                </Checkbox>
              </Stack>
            </>
          )}
          {(subject === Subject.eng || subject === Subject.deu || subject === Subject.fr) ?
            <ModalCheckList
              setItemList={setTask}
              allItemList={typeof taskList[0] === 'object' ? [] : taskList}
              buttonText={isSearchByTags ? 'Выбрать тег' : 'Выбрать корпус'}
              titleText={isSearchByTags ? 'Выберите нужный тег' : 'Выберите нужный корпус'}
              showAll={true}
              checkedItems={checkedItems}
              setCheckedItems={setCheckedItems}
              checkedAllItems={true}
            />
            :
            <Flex>
              <Text mr='16px'>{Lesson.exercise[subject]}</Text>
              {taskList && taskList.length && (
                <Select
                  onChange={e => setTask(e.target.value)}
                  value={task}
                  placeholder={Lesson.chooseExercise[subject]}
                >
                  {taskList.map((task, id) => {

                      {
                          if ((subject === Subject.phys || subject === Subject.math || subject === Subject.essay) && typeof task === 'object') {
                          return (
                            <option value={Object.keys(task)[0]} key={`task-option-${id}`}>
                              {Object.values(task)[0]}
                            </option>
                          )
                        }
                        if (typeof task !== 'object') {
                          return (
                            <option value={task} key={`task-option-${id}`}>
                              {task}
                            </option>
                          )
                        }
                      }
                  }
                  )}
                </Select>
              )}
            </Flex>}
          {(subject === 'math' || subject === 'physics' || subject === 'essay') && (
            <Flex mt='40px'>
              {taskList && taskList.length && task && subnumbersList &&
                <>
              <Text mr='16px' whiteSpace='nowrap'>{Lesson.subNumber[subject]}</Text>
                <Select
                  id={`select-subject-${subject}`}
                  key={`select-subject-${subject}`}
                  onChange={e => setSubnumber(e.target.value)}
                  value={subnumber}
                  placeholder={Lesson.chooseSubNumber[subject]}
                  isDisabled={!task}
                >
                  {subnumbersList.map((subnumber, id) => (
                    <option value={subnumber} key={`task-option-${id}`}>
                      {subnumber}
                    </option>
                  ))}
                </Select>
                </>
              }
            </Flex>
          )}
          {subject === 'physics-sim' && (
            <Flex mt='40px'>
              {taskList && taskList.length && task && variablePartPhysSim[task] &&
              <>
                <Text mr='16px' whiteSpace='nowrap'>{Lesson.subNumber[subject]}</Text>
                <Select
                  id={`select-subject-${subject}`}
                  key={`select-subject-${subject}`}
                  onChange={e => setSubnumber(e.target.value)}
                  value={subnumber}
                  placeholder={Lesson.chooseSubNumber[subject]}
                  isDisabled={!task}
                >
                  {variablePartPhysSim[task].map((subnumber, id) => (
                    <option value={subnumber} key={`task-option-${id}`}>
                      {subnumber}
                    </option>
                  ))}
                </Select>
              </>
              }
            </Flex>
          )}
          {subject === 'physics-sim' && task && (subnumber !== '' && subnumber.length !== 0) &&
          <Flex mt='40px'>
            <Text mr='16px' whiteSpace='nowrap'>Задание:</Text>
              <Select
                onChange={e => setTaskSim(e.target.value)}
                value={taskSim}
                placeholder={'Выберите задание:'}
                isDisabled={subnumber === '' || subnumber.length === 0}
              >
                {variableTaskPhysSim[subnumber].map((subnumber, id) => (
                  <option value={subnumber} key={`task-option2-${id}`}>
                    {subnumber}
                  </option>
                ))}
              </Select>
          </Flex>
          }
          {subject === 'physics-sim' && task && (subnumber !== '' && subnumber.length !== 0) && taskSim &&
          <Flex mt='40px'>
            <Text mr='16px' whiteSpace='nowrap'>Шаблон:</Text>
            <Select
              onChange={e => setPhysSim(e.target.value)}
              value={physSim}
              placeholder={'Выберите шаблон:'}
              isDisabled={taskSim === '' || taskSim.length === 0}
            >
              {variableTemplatePhysSim[taskSim].map((subnumber, id) => (
                <option value={subnumber} key={`task-option2-${id}`}>
                  {subnumber}
                </option>
              ))}
            </Select>
          </Flex>
          }
          {(subject === Subject.eng || subject === Subject.deu || subject === Subject.fr) && (
            <Box mt={'20px'}>
              <ModalCheckList
                setItemList={setSubnumber}
                allItemList={!Array.isArray(subnumbersList) ? [] : subnumbersList}
                titleText={'Выберите части речи'}
                buttonText={'Выбрать части речи'}
                showAll={true}
                checkedItems={checkedSubItems}
                setCheckedItems={setCheckedSubItems}
                checkedAllItems={true}
              />
              <Checkbox
                mt={'10px'}
                size={'lg'}
                isChecked={isUniqueTaskEng}
                onChange={handleUniqueTaskEng}
              >
                <Tooltip label={'Задание называется уникальным, когда question' +
                '(слово, которое необходимо написать в правильной форме) ' +
                'не равно answer(слово, которое является верным ответом), ' +
                'простыми словами ответ и исходное слово являются одним и тем же набором символов'}>
                  Уникальные задания
                </Tooltip>
              </Checkbox>
            </Box>
          )
          }
          <Button onClick={onSubmit} mt='40px' variant='solid'
                  isDisabled={(subject === Subject.physSim ||subject === Subject.eng || subject === Subject.deu || subject === Subject.fr) ? handleDisabled() : false}>
            {subject === 'physics-sim'? 'Перейти к симуляции' : buttonText}
          </Button>
          <Box position={'fixed'} right={'15%'} bottom={'50px'}>
            <Faq title={'Подсказка Check'} text={'Что делать Check'} />
          </Box>
        </Box>
      }
    </>
  );
};

export default CheckWelcomeScreen;
