import React, { useState } from 'react';
import {
  Button,
  Input,
  InputGroup,
  InputRightElement,
  Stack,
  FormControl,
  FormLabel,
  Link,
  FormErrorMessage,
  createStandaloneToast,
} from '@chakra-ui/react';

import { useHistory } from 'react-router-dom';
import API from '../api';
import { LoadingComponentNoWorker } from './LoadingComponentNoWorker';

const toast = createStandaloneToast();

export const Signup = () => {
  const [form, setForm] = useState({
    email: '',
    name: '',
    password: '',
    coupon: '',
  });

  const [errors, setErrors] = useState([]);
  const [showPassword, setShowPassword] = useState(false);
  const [isLoadingPageBecauseNoWorker, setIsLoadingPageBecauseNoWorker] = useState(false);

  let history = useHistory();

  const signupAgain = (lastTimeoutID) => {
    window.clearTimeout(lastTimeoutID);
    API.signup(form).then(resp => {
      setIsLoadingPageBecauseNoWorker(false);
      toast({
        title: 'Вы зарегистрированы',
        description: 'Вы будете перенаправлены на страницу входа',
        status: 'success',
        duration: 5000,
        isClosable: true,
      });
      history.push('/login');
    }).catch(err => {
      if (err?.response?.status === 605) {
        let timeoutID = setTimeout(() => {
          signupAgain(timeoutID);
        }, 10000);
      } else {
        setIsLoadingPageBecauseNoWorker(false);
        console.error(err);
        toast({
          title: 'Ошибка',
          description: 'Что-то пошло не так, попробуйте еще раз',
          status: 'error',
          duration: 5000,
          isClosable: true,
        });
      }
    });
  };

  const handleFormChange = (property, value) => {
    const newErrors = errors.filter(err => err !== property);
    setErrors(newErrors);

    const newFormData = { ...form };
    newFormData[property] = value;
    setForm(newFormData);
  };

  const validateForm = () => {
    const validation = [];
    Object.keys(form).forEach(item => {
      if (form[item] === '') {
        validation.push(item);
      }
    });
    setErrors(validation);

    return validation.length === 0;
  };

  const handleSignup = e => {
    e.preventDefault();

    if (validateForm()) {
      API.signup(form)
        .then(res => {
          toast({
            title: 'Вы зарегистрированы',
            description: 'Вы будете перенаправлены на страницу входа',
            status: 'success',
            duration: 5000,
            isClosable: true,
          });
          history.push('/login');
        })
        .catch(err => {
          if (err?.response?.status === 605) {
            setIsLoadingPageBecauseNoWorker(true);
            let timeoutID = setTimeout(() => {
              signupAgain(timeoutID);
            }, 10000);
          } else {
            console.error(err);
            toast({
              title: 'Ошибка',
              description: 'Что-то пошло не так, попробуйте еще раз',
              status: 'error',
              duration: 5000,
              isClosable: true,
            });
          }
        });
    }
  };

  const isInvalid = field => errors.includes(field);

  return (
    <>
      {isLoadingPageBecauseNoWorker ? <LoadingComponentNoWorker />
        :
        <form onSubmit={handleSignup}>
          <Stack width='400px'>
            <FormControl isInvalid={isInvalid('email')}>
              <FormLabel>Email:</FormLabel>
              <Input
                type='email'
                placeholder='email@email.com'
                value={form.email}
                onChange={e => handleFormChange('email', e.target.value)}
              />
              {isInvalid('email') && (
                <FormErrorMessage>Поле email не может быть пустым</FormErrorMessage>
              )}
            </FormControl>
            <FormControl isInvalid={isInvalid('name')}>
              <FormLabel>Имя:</FormLabel>
              <Input
                value={form.name}
                onChange={e => handleFormChange('name', e.target.value)}
              />
              {isInvalid('name') && (
                <FormErrorMessage>Поле имени не может быть пустым</FormErrorMessage>
              )}
            </FormControl>
            <FormControl isInvalid={isInvalid('password')}>
              <FormLabel>Пароль:</FormLabel>
              <InputGroup size='md'>
                <Input
                  value={form.password}
                  type={showPassword ? 'text' : 'password'}
                  onChange={e => handleFormChange('password', e.target.value)}
                />
                <InputRightElement width='4.5rem'>
                  <Button
                    h='1.75rem'
                    mr='8px'
                    size='xs'
                    onClick={() => setShowPassword(!showPassword)}
                  >
                    {showPassword ? 'Спрятать' : 'Показать'}
                  </Button>
                </InputRightElement>
              </InputGroup>
              {isInvalid('password') && (
                <FormErrorMessage>
                  Поле пароля не может быть пустым
                </FormErrorMessage>
              )}
            </FormControl>
            <FormControl isInvalid={isInvalid('coupon')}>
              <FormLabel>Купон:</FormLabel>
              <Input
                value={form.coupon}
                onChange={e => handleFormChange('coupon', e.target.value)}
              />
              {isInvalid('coupon') && (
                <FormErrorMessage>
                  Поле купона не может быть пустым
                </FormErrorMessage>
              )}
            </FormControl>
            <Button type='submit' width='100%' variant='solid'>
              Зарегистрироваться
            </Button>
            <Link href='/login' fontSize='sm'>
              Я уже зарегистрирован, перейти к логину
            </Link>
          </Stack>
        </form>}
    </>
  );
};
