import React from 'react';
import { Flex, Text, Input, Button, Tooltip } from '@chakra-ui/react';

const Limit = ({
  limit,
  limits,
  handleUpdateLimits,
  handleRemoveLimit,
  handleAddLimit,
  isLastLimit,
}) => {
  return (
    <Flex justify="flex-start" align="center">
      <Tooltip label="Диапазон переменной. Например, для скорости велосипедиста v1 в км/ч границы: от 1 до 10. Диапазоны задаются для основных переменных задачи - тех, через которые выражаются остальные переменные.">
        <Text mr="8px" fontSize="md">
          Диапазон:
        </Text>
      </Tooltip>
      <Input
        placeholder="a"
        width="40px"
        mr="8px"
        padding="4px"
        textAlign="center"
        value={limit.variable || ''}
        onChange={e => handleUpdateLimits(e.target.value, 'variable', limit.id)}
      />
      <Input
        placeholder="1"
        width="40px"
        mr="8px"
        padding="4px"
        textAlign="center"
        value={limit.bottom || ''}
        onChange={e => handleUpdateLimits(e.target.value, 'bottom', limit.id)}
      />
      {`<`}
      <Input
        placeholder="10"
        width="40px"
        ml="8px"
        padding="4px"
        textAlign="center"
        value={limit.top || ''}
        onChange={e => handleUpdateLimits(e.target.value, 'top', limit.id)}
      />
      {isLastLimit ? (
        <Button ml="8px" onClick={handleAddLimit}>
          +
        </Button>
      ) : (
        <Button ml="8px" onClick={() => handleRemoveLimit(limit.id)}>
          -
        </Button>
      )}
    </Flex>
  );
};

export default Limit;
