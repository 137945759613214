import React, { useState, useEffect } from 'react';
import {
  Button,
  Stack,
  Flex,
  Text,
  Textarea,
  Tooltip,
  Box,
  createStandaloneToast, Spinner,
} from '@chakra-ui/react';
import Limit from './Limit';
import InlineMathJaxInput from './InlineMathJaxInput';
import API from '../../api';
import ValueInput from './ValueInput';
import { ExactScienceUploadPreview } from './ExactScienceUploadPreview';
import { useHistory } from 'react-router-dom';
import { useQuery } from '../../utils';

import uniqid from 'uniqid';
import Faq from '../FAQ';
import FaqMathUpload from '../FAQ/faqMathUpload';
import {
  LoadingDescriptionToast,
  SuccessDescriptionToast,
  ErrorDescriptionToast,
  ErrorDescriptionToast401,
  ErrorDescriptionToast402,
  ErrorDescriptionToast403,
  ErrorDescriptionToastAll,
} from './toastDescription';

const toast = createStandaloneToast();

const variableGetTemplateConfig = (subject) => {
  if (subject === 'physics')
    return API.getTemplateConfigPhysics
  else if (subject === 'essay')
    return API.getTemplateConfigEssay
  else
    return API.getTemplateConfig
}

const variableGenerateTasks = (subject) => {
  if (subject === 'physics')
    return API.generateTasksPhysics
  else if (subject === 'essay')
    return API.generateTasksEssay
  else
    return API.generateTasks
}

const variableSaveGeneratedTasks = (subject) => {
  if (subject === 'physics')
    return API.saveGeneratedTasksPhysics
  else if (subject === 'essay')
    return API.saveGeneratedTasksEssay
  else
    return API.saveGeneratedTasks
}

const ExactScienceUpload = ({subject}) => {
  const query = useQuery();
  const task = query.get('task');
  const subnumber = query.get('subnumber');

  const history = useHistory();

  const [limits, setLimits] = useState([{ id: 0 }]);
  const [values, setValues] = useState([{ id: 0 }]);
  const [conditions, setConditions] = useState([{ id: 0 }]);
  const [answersFormulas, setAnswersFormulas] = useState([{ id: 0 }]);
  const [answersConditions, setAnswersConditions] = useState([{ id: 0 }]);
  const [taskText, setTaskText] = useState('');
  const [showPreview, setShowPreview] = useState(false);
  const [isLoadingPreview, setIsLoadingPreview] = useState(false);
  const [previewData, setPreviewData] = useState([]);
  const [keyTask, setKeyTask] = useState(0);
  const [isLoadingTasks, setIsLoadingTasks] = useState(false);

  const convertTemplateToForm = ({ data }) => {
    // set limits
    console.log(' --- convertTemplateToForm', data)
    const newLimits = Object.keys(data.limits).map((key, id) => ({
      id: uniqid(),
      variable: key,
      bottom: data.limits[key][0],
      top: data.limits[key][1],
    }));

    setLimits(newLimits);

    // set values
    const newValues = data.values.map((value, id) => {
      const strArr = value.split('=');
      return {
        variable: strArr[0].trim(),
        formula: strArr[1].trim(),
        id: uniqid(),
      };
    });
    setValues(newValues);

    // setConditions
    const newConditions = data.conditions.map((condition, id) => ({
      id: uniqid(),
      formula: condition,
    }));
    setConditions(newConditions);

    // setAnswerFormula
    const newAnswerFormulas = data.answer_formula.map((answForm, id) => ({
      id: uniqid(),
      formula: answForm,
    }));
    setAnswersFormulas(newAnswerFormulas);

    // setAnswerConditions
    const newAnswerConditions = data.answer_conditions.map((answCond, id) => ({
      id: uniqid(),
      formula: answCond,
    }));
    setAnswersConditions(newAnswerConditions);

    setTaskText(data.text);
  };

  useEffect(() => {
    if (subnumber !== 'new') {
      const getTemplateConfig = variableGetTemplateConfig(subject);
      getTemplateConfig({ task, subnumber }).then(resp => {
        return convertTemplateToForm(resp);
      });
    }
  }, [task, subnumber]);

  const handleUpdateValues = (arr, setFunc) => (value, field, id) => {
    let currValue = arr.find(val => val.id === id);
    let newArr = [...arr];
    currValue[field] = value;
    newArr[id] = { ...currValue };
    setFunc(newArr);
  };

  const handleAddValue = (arr, setFunc) => () => {
    setFunc([...arr, { id: uniqid() }]);
  };

  const handleRemoveValue = (arr, setFunc) => id => {
    const newArr = arr.filter(val => val.id !== id);
    setFunc(newArr);
  };

  const prepareData = () => {
    const formatLimits = () => {
      const newLimits = {};
      limits.forEach(limit => {
        newLimits[limit.variable] = [
          parseInt(limit.bottom),
          parseInt(limit.top),
        ];
      });
      return newLimits;
    };

    // from {formula: "100"​, id: 0​​, variable: "b"}
    // to ["c = b ** 2", "d = a * c"]
    const formatValues = () =>
      values.map(value => `${value.variable} = ${value.formula}`);

    // from {formula: "dfdsdf", id: 0, ​variable: "v"}
    // to
    const formatConditions = () =>
      conditions.map(condition => condition.formula);

    const formatAnswerFormulas = () => answersFormulas.map(af => af.formula);
    const formatAnswerConditions = () =>
      answersConditions.map(af => af.formula);

    const formData = {
      limits: formatLimits(),
      values: formatValues(),
      conditions: formatConditions(),
      answer_formula: formatAnswerFormulas(),
      answer_conditions: formatAnswerConditions(),
      text: taskText,
    };

    setIsLoadingPreview(true);

    return formData;
  };

  const handleGetPreview = () => {
    const ex = task + '.' + subnumber;
    setIsLoadingTasks(true);

    const generateTasks = variableGenerateTasks(subject)
    generateTasks({
      formData: prepareData(),
      task,
      subnumber,
    })
      .then(resp => {
        setIsLoadingTasks(false);
        setIsLoadingPreview(false);
        setShowPreview(true);
        setPreviewData(resp.data);
      })
      .catch(err => {
        setIsLoadingTasks(false);

        console.error(err);
        if (err?.response?.status === 401) {
          toast({
            description: <ErrorDescriptionToast401  exercise={ex + ` "${err.response.data.data}"`}/>,
            status: 'error',
            position: 'bottom-left',
            duration: null,
            isClosable: true,
          });
        }
        else if (err?.response?.status === 402) {
          toast({
            description: <ErrorDescriptionToast402  exercise={ex + ` "${err.response.data.data}"`}/>,
            status: 'error',
            position: 'bottom-left',
            duration: null,
            isClosable: true,
          });
        }
        else if (err?.response?.status === 403) {
          toast({
            description: <ErrorDescriptionToast403  exercise={ex + ` "${err.response.data.data}"`}/>,
            status: 'error',
            position: 'bottom-left',
            duration: null,
            isClosable: true,
          });
        }
        else {
          toast({
            description: <ErrorDescriptionToastAll  exercise={ex}/>,
            status: 'error',
            position: 'bottom-left',
            duration: null,
            isClosable: true,
          });
        }
      });
  };

  const handleSubmitTemplate = () => {
    const ex = task + '.' + subnumber;
    const key = ex + '-' + keyTask;
    setKeyTask(prev => ++prev);

    toast({
      id: `${key}`,
      description: <LoadingDescriptionToast exercise={ex} />,
      status: 'info',
      position: 'bottom-left',
      duration: null,
      isClosable: false,
    });

    const saveGeneratedTasks = variableSaveGeneratedTasks(subject);
    saveGeneratedTasks({
      formData: prepareData(),
      task,
      subnumber,
    })
      .then(resp => {

        if (toast.isActive(key)) {
          toast.update(key, {
            duration: 10})
        }

        toast({
          description: <SuccessDescriptionToast  exercise={ex}/>,
          status: 'success',
          position: 'bottom-left',
          duration: null,
          isClosable: true,
        });

      })
      .catch(err => {
        if (toast.isActive(key)) {
          toast.update(key, {
            duration: 10})
        }

        console.error(err);
        toast({
          description: <ErrorDescriptionToast exercise={ex} />,
          status: 'error',
          position: 'bottom-left',
          duration: null,
          isClosable: true,
        });
        setIsLoadingPreview(false);
      });

    history.push('/upload');
  };

  const handleBackToEdit = () => {
    setShowPreview(false);
    setPreviewData([]);
  };

  return (
    <Box width='600px'>
      {showPreview ? (
        <ExactScienceUploadPreview
          data={previewData}
          isLoading={isLoadingPreview}
          handleBackToEdit={handleBackToEdit}
          onSubmitForUpload={handleSubmitTemplate}
        />
      ) : (
        <Box>
          {isLoadingTasks ?
          (<Box>
              <Spinner
                thickness='4px'
                speed='1s'
                emptyColor='gray.200'
                color='blue.500'
                size='xl'
              />
              <Tooltip
                label={'Вы можете подождать генерацию заданий, или можете открыть новую страницу и перейти к другим важным делам, а после вернуться на текущую, когда генерация заданий будет успешно выполнена'}>
                <Text c fontWeight={'medium'}>Пожалуйста, не закрывайте эту страницу, идет генерация заданий...</Text>
              </Tooltip>
            <Button onClick={() => {window.open("https://ibls-review.rebels.ai/home", "_blank")}}>Открыть новую страницу</Button>
            </Box>) :
            (<Stack direction='column' spacing={4}>
              {limits.map((limit, idx) => (
                <Limit
                  limit={limit}
                  limits={limits}
                  handleUpdateLimits={handleUpdateValues(limits, setLimits)}
                  handleRemoveLimit={handleRemoveValue(limits, setLimits)}
                  handleAddLimit={handleAddValue(limits, setLimits)}
                  isLastLimit={idx === limits.length - 1}
                  key={`limit-${limit.id}`}
                />
              ))}

              {values.map((value, idx) => (
                <ValueInput
                  label='Переменные'
                  tooltipText='Вспомогательные переменные, нужные для задачи. Например, если в задаче пристутсвует скорость мотоциклиста v2, вдвое большая скорости велосипедиста, она задаётся формулой v2 = 2*v1.'
                  value={value}
                  values={values}
                  handleUpdateValues={handleUpdateValues(values, setValues)}
                  handleRemoveValue={handleRemoveValue(values, setValues)}
                  handleAddValue={handleAddValue(values, setValues)}
                  isLastValue={idx === values.length - 1}
                  key={`value-${value.id}`}
                />
              ))}

              {conditions.map((condition, idx) => (
                <InlineMathJaxInput
                  label='Ограничения'
                  tooltipText='Ограничения на переменные в форме уравнений или равенств. Например ограничение t1 + t2 <= 10 означает, что сумма t1 и t2 не будет превышать 10, условие v1 % 2 == 0 задаёт равентво нулю остатка от деления на 2. Синтаксис ограничений - синтаксис языка Python.'
                  value={condition}
                  values={conditions}
                  handleUpdateValues={handleUpdateValues(conditions, setConditions)}
                  handleRemoveValue={handleRemoveValue(conditions, setConditions)}
                  handleAddValue={handleAddValue(conditions, setConditions)}
                  isLastValue={idx === conditions.length - 1}
                  key={`condition-${condition.id}`}
                />
              ))}

              {answersFormulas.map((answerFormula, idx) => (
                <InlineMathJaxInput
                  label='Формула ответа'
                  tooltipText='Значение правильного ответа для задачи. Если в задаче несколько правильных ответов, можно создать несколько формул с ответами.'
                  value={answerFormula}
                  values={answersFormulas}
                  handleUpdateValues={handleUpdateValues(
                    answersFormulas,
                    setAnswersFormulas,
                  )}
                  handleRemoveValue={handleRemoveValue(
                    answersFormulas,
                    setAnswersFormulas,
                  )}
                  handleAddValue={handleAddValue(
                    answersFormulas,
                    setAnswersFormulas,
                  )}
                  isLastValue={idx === answersFormulas.length - 1}
                  key={`answerFormula-${answerFormula.id}`}
                />
              ))}

              {answersConditions.map((answerCondition, idx) => (
                <InlineMathJaxInput
                  label='Условия на ответ'
                  tooltipText='Если требуется обеспечить какие-то специфические условия для значения ответа, можно описать их прямо здесь, ответ в выражении следует выражать через переменную answer.
              Замечание: эту операцию можно сделать через ограничения и вспомогательные переменные, условия на ответ добавлены для удобства фильтрации - например, нецелочисленных ответов.'
                  value={answerCondition}
                  values={answersConditions}
                  handleUpdateValues={handleUpdateValues(
                    answersConditions,
                    setAnswersConditions,
                  )}
                  handleRemoveValue={handleRemoveValue(
                    answersConditions,
                    setAnswersConditions,
                  )}
                  handleAddValue={handleAddValue(
                    answersConditions,
                    setAnswersConditions,
                  )}
                  isLastValue={idx === answersConditions.length - 1}
                  key={`answerCondition-${answerCondition.id}`}
                />
              ))}

              <Flex justify='flex-start' align='center'>
                <Tooltip
                  label='Текст условия задачи. Например, в условии: "Велосипедист едет со скоростью  [v1] из пункта А в пункт Б", - в [v1] генератор подставит конкретное число.'>
                  <Text mr='8px' fontSize='md' whiteSpace='nowrap'>
                    Текст шаблона:
                  </Text>
                </Tooltip>
                <Textarea
                  placeholder="Текст условия задачи"
                  mr='8px'
                  padding='8px'
                  value={taskText}
                  onChange={e => setTaskText(e.target.value)}
                />
              </Flex>

              <Button
                onClick={handleGetPreview}
                isDisabled={isLoadingTasks}
              >
                Сохранить и сгенерировать задачи
              </Button>
            </Stack>)
          }
        </Box>
      )}
      <Box position={'fixed'} right={'15%'} bottom={'50px'}>
        <Faq title={'FAQ для методистов'} text={<FaqMathUpload />} />
      </Box>
    </Box>
  );
};

export default ExactScienceUpload;
