import React, { useState, useEffect } from 'react';
import {
  Button,
  Stack,
  Text,
  Textarea,
  Tooltip,
  Box,
  createStandaloneToast,
  Spinner,
} from '@chakra-ui/react';
import API from '../../api';
import { useHistory } from 'react-router-dom';
import { useQuery } from '../../utils';

import uniqid from 'uniqid';
import Faq from '../FAQ';
import FaqMathUpload from '../FAQ/faqMathUpload';
import {
  LoadingDescriptionToast,
  ErrorDescriptionToast401,
  ErrorDescriptionToast402,
  ErrorDescriptionToast403,
  ErrorDescriptionToastAll,
  SuccessDescriptionToast,
} from './toastDescription';

const toast = createStandaloneToast();

const variableGetTemplateConfig = (subject) => {
  if (subject === 'essay')
    return API.getTemplateConfigEssay
  else
    return API.getTemplateConfig
}

const variableGenerateTasks = (subject) => {
  if (subject === 'essay')
    return API.generateTasksEssay
  else
    return API.generateTasks
}

const variableSaveGeneratedTasks = (subject) => {
  if (subject === 'essay')
    return API.saveGeneratedTasksEssay
  else
    return API.saveGeneratedTasks
}

const EssayUpload = ({subject}) => {
  const query = useQuery();
  const task = query.get('task');
  const subnumber = query.get('subnumber');

  const history = useHistory();

  const [limits, setLimits] = useState([{ id: 0 }]);
  const [values, setValues] = useState([{ id: 0 }]);
  const [conditions, setConditions] = useState([{ id: 0 }]);
  const [answersFormulas, setAnswersFormulas] = useState([{ id: 0 }]);
  const [answersConditions, setAnswersConditions] = useState([{ id: 0 }]);
  const [taskText, setTaskText] = useState('');
  const [taskText2, setTaskText2] = useState('');
  const [showPreview, setShowPreview] = useState(false);
  const [isLoadingPreview, setIsLoadingPreview] = useState(false);
  const [previewData, setPreviewData] = useState([]);
  const [keyTask, setKeyTask] = useState(0);
  const [isLoadingTasks, setIsLoadingTasks] = useState(false);

  const convertTemplateToForm = ({ data }) => {
    // set limits
    console.log(' --- convertTemplateToForm', data)
    const newLimits = Object.keys(data.limits).map((key, id) => ({
      id: uniqid(),
      variable: key,
      bottom: data.limits[key][0],
      top: data.limits[key][1],
    }));

    setLimits(newLimits);

    // set values
    const newValues = data.values.map((value, id) => {
      const strArr = value.split('=');
      return {
        variable: strArr[0].trim(),
        formula: strArr[1].trim(),
        id: uniqid(),
      };
    });
    setValues(newValues);

    // setConditions
    const newConditions = data.conditions.map((condition, id) => ({
      id: uniqid(),
      formula: condition,
    }));
    setConditions(newConditions);

    // setAnswerFormula
    const newAnswerFormulas = data.answer_formula.map((answForm, id) => ({
      id: uniqid(),
      formula: answForm,
    }));
    setAnswersFormulas(newAnswerFormulas);

    // setAnswerConditions
    const newAnswerConditions = data.answer_conditions.map((answCond, id) => ({
      id: uniqid(),
      formula: answCond,
    }));
    setAnswersConditions(newAnswerConditions);

    setTaskText(data.text);
    setTaskText2(data.text2);
  };

  useEffect(() => {
    if (subnumber !== 'new') {
      const getTemplateConfig = variableGetTemplateConfig(subject);
      getTemplateConfig({ task, subnumber }).then(resp => {
        return convertTemplateToForm(resp);
      });
    }
  }, [task, subnumber]);

  const prepareData = () => {
    const formData = {
      text: taskText,
      text2: taskText2,
    };
    setIsLoadingPreview(true);
    return formData;
  };

  const handleGetPreview = () => {
    const ex = task + '.' + subnumber;

    const key = ex + '-' + keyTask;
    setKeyTask(prev => ++prev);

    toast({
      id: `${key}`,
      description: <LoadingDescriptionToast exercise={ex} />,
      status: 'info',
      position: 'bottom-left',
      duration: 5000,
      isClosable: false,
    });
    setIsLoadingTasks(true);

    const generateTasks = variableGenerateTasks(subject)
    generateTasks({
      formData: prepareData(),
      task,
      subnumber,
    })
      .then(resp => {
        setIsLoadingTasks(false);
        setIsLoadingPreview(false);
        setShowPreview(true);
        setPreviewData(resp.data);

        toast({
          description: <SuccessDescriptionToast exercise={ex} />,
          status: 'success',
          position: 'bottom-left',
          duration: 10000,
          isClosable: true,
        });
      })
      .catch(err => {
        setIsLoadingTasks(false);

        console.error(err);
        if (err?.response?.status === 401) {
          toast({
            description: <ErrorDescriptionToast401  exercise={ex + ` "${err.response.data.data}"`}/>,
            status: 'error',
            position: 'bottom-left',
            duration: 5000,
            isClosable: true,
          });
        }
        else if (err?.response?.status === 402) {
          toast({
            description: <ErrorDescriptionToast402  exercise={ex + ` "${err.response.data.data}"`}/>,
            status: 'error',
            position: 'bottom-left',
            duration: 5000,
            isClosable: true,
          });
        }
        else if (err?.response?.status === 403) {
          toast({
            description: <ErrorDescriptionToast403  exercise={ex + ` "${err.response.data.data}"`}/>,
            status: 'error',
            position: 'bottom-left',
            duration: 5000,
            isClosable: true,
          });
        }
        else {
          toast({
            description: <ErrorDescriptionToastAll  exercise={ex}/>,
            status: 'error',
            position: 'bottom-left',
            duration: 5000,
            isClosable: true,
          });
        }
      });
    history.push('/home');
  };

  return (
    <Box width='800px'>
        <Box>
          {isLoadingTasks ?
            (<Box>
              <Spinner
                thickness='4px'
                speed='1s'
                emptyColor='gray.200'
                color='blue.500'
                size='xl'
              />
              <Tooltip
                label={'Вы можете подождать генерацию заданий, или можете открыть новую страницу и перейти к другим важным делам, а после вернуться на текущую, когда генерация заданий будет успешно выполнена'}>
                <Text c fontWeight={'medium'}>Пожалуйста, не закрывайте эту страницу, идет генерация заданий...</Text>
              </Tooltip>
              <Button onClick={() => {window.open("https://ibls-review.rebels.ai/home", "_blank")}}>Открыть новую страницу</Button>
            </Box>) :
            (<Stack direction='column' align='left' spacing={4} padding='8px' borderColor='gray.400'>>
              <Box  align='left' mb='18px'>
                <Box mb='18px'>
                 <Text mr='8px' fontSize='md' whiteSpace='nowrap' as='b'>
                    Текст:
                  </Text>
                <Textarea
                  placeholder="Текст для изложения"
                  mr='8px'
                  padding='8px'
                  minHeight='300px'
                  borderColor='gray.400'
                  borderRadius='sm'
                  value={taskText}
                  onChange={e => setTaskText(e.target.value)}
                />
                </Box>
                <Box mb='18px'>
                  <Text mr='8px' fontSize='md' whiteSpace='nowrap' as='b'>
                    Темы:
                  </Text>
                  <Textarea
                    placeholder="Темы"
                    mr='8px'
                    padding='8px'
                    borderColor='gray.400'
                    borderRadius='sm'
                    value={taskText2}
                    onChange={e => setTaskText2(e.target.value)}
                  />
                </Box>
              </Box>
              <Button
                variant='solid'
                onClick={handleGetPreview}
                isDisabled={isLoadingTasks}
              >
                Сохранить и сгенерировать задачи
              </Button>
            </Stack>)
          }
        </Box>
      <Box position={'fixed'} right={'15%'} bottom={'50px'}>
        <Faq title={'FAQ для методистов'} text={<FaqMathUpload />} />
      </Box>
    </Box>
  );
};

export default EssayUpload;
