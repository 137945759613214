import React, { useEffect, useState, useCallback } from 'react';
import {
  Box,
  Button,
  Flex,
  Stack,
  Text,
  Spinner,
  createStandaloneToast,
  Textarea,
  HStack,
} from '@chakra-ui/react';
import { withRouter, useHistory } from 'react-router-dom';
import API from '../api';
import { countWords, mathJaxConfig, useQuery } from '../utils';
import Faq from './FAQ';
import FaqMathCheck from './FAQ/faqMathCheck';
import { LoadingComponentNoWorker } from './LoadingComponentNoWorker';
import { EssayCheck } from './EssayCheck/EssayCheck';
// import AudioPlayer from 'react-h5-audio-player';
const pageSize = 10;
const toast = createStandaloneToast();

const variableCheckExactScience = (subject) => {
  if (subject === 'physics')
    return API.checkPhysics
  else if (subject === 'essay')
    return API.checkEssay
  else
    return API.checkMath
}

const variableGetExactScience = (subject) => {
  console.log(' : 38 subject', subject);
  if (subject === 'physics') {
    return API.getPhysics;
  } else if (subject === 'essay') {
    console.log(' : 42 API.getEssay', API.getEssay);
    return API.getEssay;
  } else {
    console.log(' : 45 API.getMath', API.getMath);
    return API.getMath;
  }
}


const EssayComponent = ({subject}) => {
  const query = useQuery();
  const task = query.get('task');
  const subnumber = query.get('subnumber');
  const history = useHistory();

  const [data, setData] = useState([]);
  const [page, setPage] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingCheck, setIsLoadingCheck] = useState(false);
  const [isChecked, setIsChecked] = useState(false);
  const [showResult, setShowResult] = useState(false);
  const [isLoadingPageBecauseNoWorker, setIsLoadingPageBecauseNoWorker] = useState(false);
  const [enteredText, setEnteredText] = useState('');
  const [audioData, setAudioData] = useState(null);

  const checkMathAgain = (lastTimeoutID, dataToSend) => {
    window.clearTimeout(lastTimeoutID);
    const checkExactScience = variableCheckExactScience(subject);
    console.log(': checkExactScience', checkExactScience)
    checkExactScience({ updatedData: dataToSend, task, subnumber })
      .then(() => {
        setIsLoadingPageBecauseNoWorker(false);
        setIsLoadingCheck(false);
        setIsChecked(true);
        toast({
          title: 'Спасибо',
          description: 'Результаты проверки сохранены',
          status: 'success',
          duration: 3000,
          isClosable: true,
        });
        handleGoToNext();
      }).catch(err => {
      if (err?.response?.status === 605){
        setIsLoadingPageBecauseNoWorker(true);
        let timeoutID = setTimeout(() => {
          checkMathAgain(timeoutID, dataToSend);
        }, 10000);
      }
      else {
        setIsLoadingPageBecauseNoWorker(false);
        console.error(err);
        toast({
          title: 'Ошибка',
          description:
            'Невозможно сохранить результаты проверки, попробуйте еще раз',
          status: 'error',
          duration: 5000,
          isClosable: true,
        });
      }
    });
  }

  const fetchMath = useCallback(() => {
    const getExactScience = variableGetExactScience(subject);
    console.log(' : 1 getExactScience', getExactScience)
    getExactScience({ task, pageSize, subnumber })
      .then(resp => {
        if (resp === undefined) {
          toast({
            title: 'Ошибка',
            description: 'Невозможно загрузить задание c такими параметрами',
            status: 'error',
            duration: 5000,
            isClosable: true,
          });
          history.push('/');
        }
        setData(resp);
        setIsLoading(false);

        if (resp.audio) {
          setAudioData(resp.audio);
        }
      })
      .catch(err => {
        toast({
          title: 'Ошибка',
          description: 'Невозможно загрузить задание, попробуйте еще раз',
          status: 'error',
          duration: 5000,
          isClosable: true,
        });
        history.push('/');
      });
    console.log(' : 2 getExactScience', getExactScience);
  }, [task, history, subnumber]);

  useEffect(() => {
    setIsLoading(true);
    fetchMath();
  }, [page, fetchMath]);

  const updateMath = () => {
    const dataToSend = data.map(({ id, check_result, comment }) => ({
      id,
      check_result: !!check_result,
      comment,
    }));
    const checkExactScience = variableCheckExactScience(subject);
    checkExactScience({ updatedData: dataToSend, task, subnumber })
      .then(() => {
        setIsLoadingCheck(false);
        setIsChecked(true);
        toast({
          title: 'Спасибо',
          description: 'Результаты проверки сохранены',
          status: 'success',
          duration: 3000,
          isClosable: true,
        });
        handleGoToNext();
      })
      .catch(err => {
        if (err?.response?.status === 605){
          setIsLoadingPageBecauseNoWorker(true);
          let timeoutID = setTimeout(() => {
            checkMathAgain(timeoutID, dataToSend);
          }, 10000);
        }
        else {
          console.error(err);
          toast({
            title: 'Ошибка',
            description:
              'Невозможно сохранить результаты проверки, попробуйте еще раз',
            status: 'error',
            duration: 5000,
            isClosable: true,
          });
        }
      });
  };

  const handleGoToNext = () => {
    setPage(page + 1);
    setIsChecked(false);
  };

  const handleCheck = () => {
    setIsLoadingCheck(true);
    updateMath();
    // Redirect to EssayCheck.js after updating math
    setShowResult(true);
    // history.push('/essay-results');
    console.log("./EssayCheck/EssayCheck t", showResult);
  };

  const handleUpdateField = (value, field, id) => {
    const taskToUpdateIndex = data.findIndex(task => task.id === id);
    data[taskToUpdateIndex][field] = value;
    setData([...data]);
    if (field === 'comment') {
      setEnteredText(value); // Update the entered text state
    }
  };
  console.log(' : currentTask data', data)
  console.log(countWords(enteredText));

  return (
    <>
      {isLoadingPageBecauseNoWorker ? (
        <LoadingComponentNoWorker />
      ) : (
        <React.Fragment>
          {!showResult && ( // Conditionally render the loading section based on showResult value
            isLoading ? (
            <Box>
              <Spinner />
              <Text>Идёт загрузка...</Text>
            </Box>
          ) : (
            <Box>
              {data.map(currentTask => (
                <Stack
                  direction='column'
                  spacing={4}
                  key={`key-${currentTask.id}`}
                >
                  <Text fontSize='2xl'>Задание {currentTask.id}</Text>
                  {<Text w='800px' align='left'>{'Прослушайте текст и напишите сжатое изложение. Учтите, что вы должны передать главное содержание как каждой микротемы, так и всего текста в целом, используя при этом приёмы сжатия. Объём изложения - от 70 до 110 слов.'}</Text>}
                  {currentTask.audio && (
                    <Box w='800px'  align='center'>
                    <audio
                      controls
                      style={{ borderRadius: '7px'}}
                    >
                      <source src={`data:audio/mpeg;base64,${currentTask.audio}`} type='audio/mpeg' />
                      Your browser does not support the audio element.
                    </audio>
                    </Box>
                  )}
                  <Stack direction='row' spacing={4} align='center'>
                  </Stack>
                  <Flex>
                    <Textarea
                      w='800px'
                      my='6px'
                      minHeight='200px'
                      borderColor='gray.400'
                      borderRadius='sm'
                      placeholder='Введите текст изложения'
                      value={enteredText}
                      onChange={e =>
                        handleUpdateField(
                          e.target.value,
                          'comment',
                          currentTask.id,
                        )
                      }
                    />
                  </Flex>
                  <HStack pb={4}><Text fontSize={16} fontWeight={500}>Объем текста: </Text> <Text fontSize={16}>{countWords(enteredText)}</Text></HStack>
                </Stack>
              ))}
              <Box position={'fixed'} right={'15%'} bottom={'50px'}>
                <Faq title={'FAQ для учеников'} text={<FaqMathCheck />} />
              </Box>
              {!isLoading && (
                <Button
                  variant='solid'
                  // colorScheme='teal'
                  onClick={handleCheck}
                  maxWidth='300px'
                  disabled={isChecked}
                  isLoading={isLoadingCheck}
                  my='6px'
                >
                  Отправить результаты на проверку
                </Button>
              )}
            </Box>
            )
          )}
        </React.Fragment>
      )}
      {showResult ? (
        <EssayCheck
          data={data}
          enteredText={enteredText}
          // isLoading={isLoadingPreview}
          // handleBackToEdit={handleBackToEdit}
          // onSubmitForUpload={handleSubmitTemplate}
        />
      ) : (
        <Stack>-</Stack>
      )}
    </>
  );
};

export default withRouter(EssayComponent);
