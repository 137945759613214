import React, { useEffect, useState } from 'react';
import { Button, Box, Select, Text, Flex, Input, createStandaloneToast } from '@chakra-ui/react';
import Faq from './FAQ';
import API from '../api';


const toast = createStandaloneToast();

const variableGetTaskList = (sub) => {
  if (sub === 'math')
    return API.getMathTasksList;
  else if (sub === 'essay')
    return API.getEssayTasksList;
  else
    return API.getPhysicsTasksList;
};

const variableRenameTheme = (sub) => {
  if (sub === 'math')
    return API.renameMathTheme;
  else if (sub === 'essay')
    return API.renameEssayTheme;
  else
    return API.renamePhysicsTheme;
};

const RenameTaskChoiceScreen = ({
                                  subject,
                                  setSubject,
                                  task,
                                  setTask
                                }) => {
  const [taskList, setTaskList] = useState([]);
  const [name, setName] = useState('');
  const [refresh, setRefresh] = useState(false);


  useEffect(() => {
    setSubject(() => 'math');
    setTask('');
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (subject === 'math' || subject === 'physics' || subject === 'essay') {
      const getTasksList = variableGetTaskList(subject);
      getTasksList().then(resp => {
        setTaskList(() => {
          return Object.keys(resp.body).map((key) => {
            return { [key]: resp.body[key] }
          }).sort((a, b) => {
            if (Object.values(a)[0].toLowerCase() < Object.values(b)[0].toLowerCase()) {
              return -1;
            }
            if (Object.values(a)[0].toLowerCase() > Object.values(b)[0].toLowerCase()) {
              return 1;
            }
            // a должно быть равным b
            return 0;
          });
        })
      })

    }
  }, [subject, refresh]) // eslint-disable-line react-hooks/exhaustive-deps

  const handleClick = () => {
    const rename = variableRenameTheme(subject);
    const old_name = Object.values(taskList.find(item => {
      const [key, value] = Object.entries(item)[0];
      if (key === task) {
        return true
      }
    }))[0];
    const new_name = name;
    rename({old_name, new_name}).then(() => {
      setRefresh((prev) => !prev);
    })
      .catch(() => {
        toast({
          title: 'Ошибка',
          description: `Не удалось переименовать тему ${old_name} на ${new_name}`,
          status: 'error',
          position: 'bottom-left',
          duration: 3000,
          isClosable: true,
        });
      })
  }

  return (
    <Box mr='auto' ml='auto'>
      <Select width='400px' mr='auto' ml='auto' mb="40px"
              onChange={e => {
                setSubject(e.target.value);
              }}
              value={subject}
      >
        <option value="math">Математика</option>
        <option value="physics">Физика</option>
        <option value="essay">Изложение</option>
      </Select>

      <Flex mb="20px">
        <Text mr='16px'>Задание:</Text>
          <Select
            onChange={e => {
              setTask(e.target.value);
            }}
            value={task}
            placeholder='Выберите задание'
          >
            {taskList.map((task, id) => {
              if (typeof task === 'object') {
                return (
                  <option value={Object.keys(task)[0]} key={`task-option-${id}`}>
                    {Object.values(task)[0]}
                  </option>
                )
              }
            })}
          </Select>
      </Flex>
        <Input placeholder={'Введите новое название темы'}
               value={name}
               onChange={(e) => setName(e.target.value)}
        />
      <Button
        onClick={handleClick}
        isDisabled={!name && !task}
        mt='40px'
        variant='solid'
      >
        Переименовать
      </Button>
      <Box position={'fixed'} right={'15%'} bottom={'50px'}>
        <Faq title={'Подсказка Delete'} text={'Что делать Delete'}/>
      </Box>
    </Box>
  );
};

export default RenameTaskChoiceScreen;
