import React from 'react';
import { useLocation } from 'react-router-dom';

function useQuery() {
  const { search } = useLocation();

  return React.useMemo(() => new URLSearchParams(search), [search]);
}

const mathJaxConfig = {
  loader: { load: ['input/asciimath', 'output/chtml'] },
};

const SUBJECTS = {
  rus: 'Русский',
  essay: 'Изложение',
  math: 'Математика',
  english: 'Английский',
  deutsch: 'Немецкий',
  francais: 'Французский',
  physics: 'Физика',
  'physics-sim': 'Физика cимуляция'
};

const countWords = (input) => {
  const text = input.trim();
  if (text === '') {
      return 0;
  }

  const words = text.split(/\s+/).filter(function(word) {
      return word.length > 0;
  });

  return words.length;
};


export { useQuery, SUBJECTS, mathJaxConfig, countWords };
