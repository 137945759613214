import React, { useState, useEffect } from 'react';
import { Box, Button, Flex, Stack, Text, Spinner, Grid, GridItem, useColorMode, VStack, } from '@chakra-ui/react';
import API from '../../api';
import { useHistory } from 'react-router-dom';
import { useQuery } from '../../utils';
export const EssayCheck = ({
  data: initialData,
  isLoading,
  onSubmitForUpload,
  handleBackToEdit,
  enteredText,
}) => {
  const query = useQuery();
  const task = query.get('task');
  const subnumber = query.get('subnumber');
  const { colorMode } = useColorMode();

  const [data, setData] = useState([]);
  const [isLoadingCheck, setIsLoadingCheck] = useState(false);
  const [essayText, setEssayText] = useState('');
  const [checkMistakes, setCheckMistakes] = useState([]);
  const [compressionInfo, setCompressionInfo] = useState({});
  const [contentInfo, setContentInfo] = useState({});
  const [isCutted, setIsCutted] = useState(false);
  const [punctuationInfo, setPunctuationInfo] = useState({});
  const [entegrityInfo, setEntegrityInfo] = useState({});

  useEffect(() => {
    setData(initialData);
    setIsLoadingCheck(true);
    // Perform HTTP POST request when component mounts
    const performPostRequest = async () => {
      try {
        // Call the checkEssayResultsMock API function here
        const response = await API.checkEssayResults({
          updatedData: {
            task: task,
            subnumber: subnumber,
            essay: enteredText,
          },
          task: task,
          subnumber: subnumber,
          essay: enteredText,
        });
        // Handle the response as needed
        console.log('POST request response:', response);
        if (response?.data?.body) {
          const {
            essay,
            check_mistakes,
            compression,
            content,
            is_cutted,
            punctuation,
            entegrity,
          } = response.data.body;

          setEssayText(essay);
          setCheckMistakes(check_mistakes || []);
          setCompressionInfo(compression || {});
          setContentInfo(content || {});
          setIsCutted(is_cutted || false);
          setPunctuationInfo(punctuation || {});
          setEntegrityInfo(entegrity || {});
        }
      } catch (error) {
        // Handle errors
        console.error('Error making POST request:', error);
      }
      setIsLoadingCheck(false);
    };
    performPostRequest();
  }, [enteredText]);

  const handleCheck = () => {
    setIsLoadingCheck(true);
    onSubmitForUpload(data);
    setIsLoadingCheck(false);
  };
  const history = useHistory();

  const handleRedirectToHome = () => {
    history.push('/home');
  };

  const getTextSegmentsGrammar = (text, wordMistakes, punctuationMistakes) => {
    const segments = [];
    let lastIndex = 0;

    // Combine word and punctuation mistakes
    const allMistakes = [...wordMistakes];
    for (const [index, char] of Object.entries(punctuationMistakes)) {
      allMistakes.push([char, parseInt(index), parseInt(index)]);
    }

    allMistakes.sort((a, b) => a[1] - b[1]);

    allMistakes.forEach(([word, start, end]) => {
      if (start > lastIndex) {
        segments.push({
          text: text.substring(lastIndex, start),
          highlight: false,
        });
      }
      segments.push({
        text: text.substring(start, end + 1),
        highlight: true,
        isPunctuation: word.length === 1,
      });
      lastIndex = end + 1;
    });

    if (lastIndex < text.length) {
      segments.push({ text: text.substring(lastIndex), highlight: false });
    }

    return segments;
  };

  return (
    <React.Fragment>
      {isLoadingCheck ? (
        <Box>
          <Spinner />
          <Text>Идёт загрузка...</Text>
        </Box>
      ) : (
        <Stack>
          <Flex>
            <VStack>
              <Text fontSize="2xl" mb="18px">
                Результаты проверки
              </Text>
              <Grid
                templateColumns="repeat(2, 1fr)"
                gap={4}
                w="1000px"
                padding="8px"
                border="1px"
                borderRadius="lg"
                borderColor="gray.400"
              >
                <GridItem rowSpan={5} colSpan={1}>
                  <Text
                    w='5q00px'
                    minHeight='700px'
                    align='left'
                    mr='8px'
                    padding='8px'
                    border='1px'
                    borderColor='gray.400'
                    bgColor={colorMode === 'dark' ? 'gray.700' : 'gray.100'}
                    borderRadius='lg'
                    overflow='hidden'
                    whiteSpace="pre-wrap"
                  >
                    {getTextSegmentsGrammar(
                      essayText,
                      checkMistakes,
                      punctuationInfo
                    ).map(segment => {
                      if (segment.highlight) {
                        const backgroundColor = segment.isPunctuation
                          ? 'yellow'
                          : 'red';
                        return (
                          <mark style={{ backgroundColor }}>
                            {segment.text}
                          </mark>
                        );
                      } else return segment.text;
                    })}
                  </Text>
                </GridItem>
                <GridItem rowSpan={1} colSpan={1}>
                  <Box  align='left' mb='18px'>
                    <Text  as='b'>
                      Объём текста:
                    </Text>
                    {isCutted.result !== undefined ? (
                      <Text ml='8px' color={isCutted.result ? 'green' : 'red'}>
                        {isCutted.result ? 'Изложение набрало достаточное количество слов' : 'Изложение не подходит под критерии оценок'}
                      </Text>
                    ) : (
                      <Text ml='8px' color='gray.200'>-</Text>
                    )}
                    <Text ml='8px'>
                      Количество слов: {isCutted.count}
                    </Text>
                  </Box>
                  <Box align='left' mb='18px'>
                    <Text as='b'>
                      Баллы за содержание изложения:
                    </Text>
                    <Text ml='8px'>
                      Количестов баллов: {JSON.stringify(contentInfo, null, 2)}
                    </Text>
                  </Box>
                  <Box align='left' mb='18px'>
                    <Text as='b'>
                      Орфографические ошибки:
                     </Text>
                    <Text ml='8px'>
                      Допущенные в изложении ошибки выделены красным цветом
                      {/*{JSON.stringify(checkMistakes, null, 2)}*/}
                    </Text>
                  </Box>
                  <Box  align='left' mb='18px'>
                    <Text as='b'>
                      Баллы за сжатие изложения:
                    </Text>
                    <Text ml='8px'>
                      Количестов баллов: {JSON.stringify(compressionInfo, null, 2)}
                    </Text>
                  </Box>
                  <Box  align='left' mb='8px'>
                    <Text as='b'>
                      Пунктуация:
                    </Text>
                    <Text ml='18px'>
                      Обратите внимание на выделенные жёлтым знаки препинаия, это могут быть ошибки или пропущенные знаки
                      {/*{JSON.stringify(punctuationInfo, null, 2)}*/}
                    </Text>
                  </Box>
                  <Box align='left' mb='18px'>
                    <Text as='b'>
                      Баллы за смысловую связанность и речевую последовательность:
                    </Text>
                    <Text ml='8px'>
                      Количество баллов: {JSON.stringify(entegrityInfo, null, 2)}
                    </Text>
                  </Box>
                </GridItem>
              </Grid>
              <Button
                variant='solid'
                // colorScheme='teal'
                maxWidth='300px'
                onClick={handleRedirectToHome}
              >
                Вернуться на главную страницу
              </Button>
            </VStack>
          </Flex>
        </Stack>
      )}
    </React.Fragment>
  );
};
