import { Box, Spinner, Text, Tooltip } from '@chakra-ui/react';
import React from 'react';

export const LoadingComponentNoWorker = () => {
  return (
  <Box>
    <Spinner
      thickness='4px'
      speed='1s'
      emptyColor='gray.200'
      color='blue.500'
      size='xl'
    />
    <Tooltip
      label={'Каждые 10 секунд, Мы проверяем, освободился ли серверв, и как только это произойдет Вы сможете продолжить'}>
      <Text c fontWeight={'medium'}>Пожалуйста, подождите, сейчас сервер занят, но скоро освободится и обработает Ваш запрос</Text>
    </Tooltip>
  </Box>
  )

}