/* eslint-disable */
import React, { useEffect } from 'react';
import { Button } from '@chakra-ui/react';
import { useHistory } from 'react-router-dom';


const PhysicsSimulationComponent = ({ id, link, setSubject }) => {

  var ggbApp;

  const history = useHistory()

  useEffect(() => {
    if (id === undefined || id === '') {
      setSubject('physics-sim')
    } else {
      localStorage.setItem('linkGeo', link);
      localStorage.setItem('idGeo', id);
      location.reload();
    }
  }, []);

  ggbApp = new GGBApplet({
    'appName': 'classic', 'width': 900, 'height': 600,
    'showToolBar': false, 'showAlgebraInput': false, 'showMenuBar': false,
    'material_id': localStorage.getItem('idGeo'), 'enableCAS': true, appletOnLoad: function(api) {
      api.evalCommandCAS('x+2x');
    },
  }, true);

  window.addEventListener('load', function() {
    ggbApp.inject('ggb-element');
  });


  // function setFunction() {
  //   var applet = document.ggbApplet;
  //   var myVar1 = document.getElementById('myVar').value;
  //   applet.evalCommand(myVar1);
  // }
  //
  // function getFunction(object) {
  //   var input = prompt('Enter a GeoGebra variable name to see its value ', object);
  //   var applet = document.ggbApplet;
  //   var inputVal = applet.getValueString(input);
  //   alert(inputVal);
  //   document.getElementById('myVALresult').innerHTML = inputVal;
  // }
  //
  // function setCAS() {
  //   var applet = document.ggbApplet;
  //   var myCASvalue = document.getElementById('myVar').value;
  //   var CASresult = applet.evalCommandCAS(myCASvalue);
  //   alert(CASresult);
  //   document.getElementById('myCASresult').innerHTML = CASresult;
  // }
  //
  // function reset() {
  //   var applet = document.ggbApplet;
  //   applet.reset();
  //   document.getElementById('myVar').value = '';
  // }
  //
  // async function sendData() {
  //   var applet = document.ggbApplet;
  //   var objcts = applet.getAllObjectNames();
  //
  //   for (let key in objcts) {
  //     let obj = objcts[key];
  //     let type = applet.getObjectType(obj);
  //
  //     console.log('>', obj, '- getObjectType -', type);
  //     if (type == 'numeric') {
  //       console.log('|', 'getValue', applet.getValue(obj));
  //       console.log('|', 'getListValue', applet.getListValue(obj));
  //       console.log('|', 'getValueString', applet.getValueString(obj));
  //       console.log('|', 'getDefinitionString', applet.getDefinitionString(obj));
  //       console.log('|', 'getCommandString', applet.getCommandString(obj));
  //       console.log('|', 'getLaTeXString', applet.getLaTeXString(obj));
  //       console.log('|', 'getAllObjectNames', applet.getAllObjectNames(obj));
  //
  //     }
  //     if (type == 'point') {
  //       console.log('|', 'coords', applet.getXcoord(obj), applet.getYcoord(obj));
  //     }
  //     console.log('|', 'getAllObjectNames', applet.getAllObjectNames(obj));
  //     console.log('|', 'getObjectNumber', applet.getObjectNumber(obj));
  //     console.log('_________________');
  //   }
  //
  //   let url = 'http://localhost:5000';
  //   let request = await fetch(url, {
  //     method: 'POST',
  //     headers: {
  //       'Accept': 'application/json',
  //       'Content-Type': 'application/json',
  //     },
  //     body: JSON.stringify({ a: objcts }),
  //   });
  //   const content = await request;
  //
  //   console.log(request);
  // }

  function handleClick() {
    setSubject('physics')
    history.push(localStorage.getItem('linkGeo'));
  }

  return (
    <div>
      <div id='ggb-element'></div>
          <Button isDisabled={ localStorage.getItem('linkGeo') === ''} onClick={handleClick} mt='40px' variant='solid'>
            { localStorage.getItem('linkGeo') !== '' && 'Перейти к задачам'}
            { localStorage.getItem('linkGeo') === '' && 'Задача по данному шаблону еще не создана'}
          </Button>
    </div>
  );
};

export default PhysicsSimulationComponent;