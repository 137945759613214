import React, { useCallback, useEffect, useState } from 'react';
import { Box, Button, Checkbox, createStandaloneToast, Input, Spinner, Stack, Text } from '@chakra-ui/react';
import API from '../api';
import { useQuery } from '../utils';
import { useHistory } from 'react-router-dom';
import Faq from './FAQ';

const pageSize = 5;
const toast = createStandaloneToast();

const Subject = {
  eng: 'english',
  deu: 'deutsch',
  fr: 'francais',
};

const variableGetLanguage = (sub) => {
  if (sub === Subject.eng)
    return API.getEnglish;
  else if (sub === Subject.fr)
    return API.getFrancais;
  else
    return API.getDeutsch;
}

const variableCheckLanguage = (sub) => {
  if (sub === Subject.eng)
    return API.checkEnglish;
  else if (sub === Subject.fr)
    return API.checkFrancais;
  else
    return API.checkDeutsch;
}

const LanguageComponent = ({subject}) => {
  const query = useQuery();
  let task = query.get('task').split(',');
  const subnumber = query.get('subnumber').split(',');
  const unique = query.get('unique');
  let subTask = query.get('subtask').split(',');
  const isSearchByTags = query.get('isSearchByTags');
  const history = useHistory();

  const [data, setData] = useState([]);
  const [page, setPage] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [showOtherInfo, setshowOtherInfo] = useState(false)

  const fetchEnglish = useCallback(() => {
    if(isSearchByTags === 'true') {
      const copyCorpus = subTask;
      subTask = task;  //eslint-disable-line react-hooks/exhaustive-deps
      task = copyCorpus; //eslint-disable-line react-hooks/exhaustive-deps
    }
    variableGetLanguage(subject)({ corpus:task, pageSize:pageSize, parts:subnumber, tags:subTask, unique:unique })
      .then(resp => {
        if (resp === undefined) {
          toast({
            title: 'Ошибка',
            description: 'Невозможно загрузить задание c такими параметрами',
            status: 'error',
            duration: 5000,
            isClosable: true,
          });
          history.push('/');
        }
        resp.forEach(ex => {
            ex.banned = 1;
        })
        setData(resp);
        setIsLoading(false);
      })
      .catch(err => {
        toast({
          title: 'Ошибка',
          description: 'Невозможно загрузить задание c такими параметрами',
          status: 'error',
          duration: 5000,
          isClosable: true,
        });
        history.push('/');
      });
  }, [history]);

  useEffect(() => {
    setIsLoading(true);
    fetchEnglish();
  }, [page, fetchEnglish]);


  const otherInfoHandler = () => {
    setshowOtherInfo(!showOtherInfo);
  }

  const handleGoToNext = () => {
    setPage(page + 1);
  };

  const handleUploadEnglish = () => {
    variableCheckLanguage(subject)({updateData: data})
      .then(() => {
        toast({
          title: 'Спасибо',
          description: 'Результаты проверки сохранены',
          status: 'success',
          duration: 3000,
          isClosable: true,
        });
        handleGoToNext();
      })
      .catch(err => {
        toast({
          title: 'Ошибка',
          description:
            'Невозможно сохранить результаты проверки, попробуйте еще раз',
          status: 'error',
          duration: 5000,
          isClosable: true,
        });
        // history.push('/');
      });

    handleGoToNext(); // delete 1 items
  };

  const handleUpdateField = (value, field, id) => {
    const taskToUpdateIndex = data.findIndex(ex => ex.id === id);
    if (typeof value === 'boolean') {
      data[taskToUpdateIndex][field] = value ? 0 : 1;
    }
    else {
      data[taskToUpdateIndex][field] = value
    }
    setData([...data]);
  };

  return isLoading ? (
    <Box>
      <Spinner />
      <Text>Идёт загрузка...</Text>
    </Box>
  ) : (
    <Box mr='100px' ml='100px'>
      {data.map(currentTask => (
        <Stack
          direction='column'
          spacing={4}
          mb='48px'
          key={`key-${currentTask.id}`}
        >
          <Text fontSize='2xl'>Задача {parseInt(currentTask.id)}</Text>
          <Text align='left'>{currentTask.exercise}</Text>
          <Stack direction='row' spacing={4} align='center' justify='left'>
            <Text fontSize='xl' fontWeight='bold'>Question:</Text>
            <Text fontSize='xl'>
              {currentTask.question}
            </Text>
            <Button onClick={otherInfoHandler} w='50px' h='50px' borderRadius='100%'>&#9660;</Button>
          </Stack>
          {showOtherInfo &&
          <Stack direction='column' spacing={4} align='start'>
            <Text fontSize='xl' mx={'auto'}>Other information about task</Text>
            <Box borderRadius='10px' backgroundColor='black' height='1px' width='100%' />
            <Text fontSize='xl'>corpus:{currentTask.corpus},</Text>
            <Text fontSize='xl'>originalId: {parseInt(currentTask.original_id)},</Text>
            <Text fontSize='xl'>morph:{currentTask.morph_id.slice(1, -1)},</Text>
            <Text fontSize='xl'>pos: {currentTask.pos}</Text>
            <Box borderRadius='10px' backgroundColor='black' height='1px' width='100%' />
          </Stack>
          }
          <Stack direction='row' spacing={4} align='center' justify='left'>

            <Text fontSize='xl' fontWeight='bold'>Ответ:</Text>
            <Text fontSize='xl'>
              {currentTask.answer}
            </Text>
            <Checkbox
              colorScheme='green'
              size='lg'
              checked={currentTask.check_result}
              onChange={e =>
                handleUpdateField(
                  e.target.checked,
                  'banned',
                  currentTask.id,
                )
              }
            >
              Задание подходит
            </Checkbox>
          </Stack>
          <Input
            placeholder='Комментарий'
            onChange={e =>
              handleUpdateField(e.target.value, 'comment', currentTask.id)
            }
          />
        </Stack>
      ))}
      <Button
        variant='solid'
        colorScheme='teal'
        onClick={handleUploadEnglish}
        maxWidth='300px'
        mb='16px'
      >
        Отправить результаты проверки
      </Button>
      <Box position={'fixed'} right={'15%'} bottom={'50px'}>
        <Faq title={'Подсказка Upload'} text={'Что делать Upload'} />
      </Box>
    </Box>
  );

}

export default LanguageComponent;