import React, { useEffect, useRef, useState } from 'react';
import {
  Box,
  Button, createStandaloneToast,
  FormControl,
  FormHelperText,
  FormLabel, Spinner,
  Stack,
  Text, Tooltip,
} from '@chakra-ui/react';
import './tag.css';
import Faq from '../FAQ';
import ModalCheckList from '../CheckList/ModalCheckList';
import API from '../../api';
import { ErrorDescriptionToast, LoadingDescriptionToast, SuccessDescriptionToast } from './toastDescription';
import { useHistory } from 'react-router-dom';
import { LoadingComponentNoWorker } from '../LoadingComponentNoWorker';

const toast = createStandaloneToast();

const requiredTags = ['Начальные классы', 'Средние классы', 'Старшие классы'];

const Subject = {
  eng: 'english',
  deu: 'deutsch',
  fr: 'francais',
};

const variableGetTaskList = (sub) => {
  if (sub === Subject.eng)
    return API.getEnglishTasksList;
  else if (sub === Subject.fr)
    return API.getFrancaisTasksList;
  else
    return API.getDeutschTasksList;
};

const variableSaveGeneratedTasks = (sub) => {
  if (sub === Subject.eng)
    return API.saveGeneratedTasksEnglish;
  else if (sub === Subject.fr)
    return API.saveGeneratedTasksFrancais;
  else
    return API.saveGeneratedTasksDeutsch;
};

const LanguageUpload = ({
                          subject,
                          setSubject,
                          tags, setTags,
                          setCorpus,
                        }) => {

  const [checkedItems, setCheckedItems] = React.useState([]);
  const corpusRef = useRef(null);
  const [showTags, setShowTags] = useState(false);
  const [allTagList, setAllTagList] = useState([]);
  const [countLoadingCorpuses, setCountLoadingCorpuses] = useState(0);
  const [keyCorpus, setKeyCorpus] = useState(0);
  const [isLoadingPageBecauseNoWorker, setIsLoadingPageBecauseNoWorker] = useState(false);
  const [isDisabledButton, setIsDisabledButton] = useState(true);
  const history = useHistory();

  const handleErrorNoWorker = (sendRequestAgain) => {
    if (!isLoadingPageBecauseNoWorker) {
      setIsLoadingPageBecauseNoWorker(true);
    }
    let timeoutID = setTimeout(() => {
      sendRequestAgain(timeoutID);
    }, 10000);
  };

  const getTaskListAgain = (lastTimeoutID) => {
    window.clearTimeout(lastTimeoutID);
    const getTasksList = variableGetTaskList(subject);
    getTasksList().then(resp => {
      setAllTagList(resp.dataTags);
      setIsLoadingPageBecauseNoWorker(false);
    }).catch(err => {
      if (err?.response?.status === 605) {
        let timeoutID = setTimeout(() => {
          getTaskListAgain(timeoutID);
        }, 10000);
      } else {
        setIsLoadingPageBecauseNoWorker(false);
      }
    });
  };

  const saveGeneratedTasksAgain = ({ lastTimeoutID, file, tags, key, nameOfCorpus }) => {
    window.clearTimeout(lastTimeoutID);
    variableSaveGeneratedTasks(subject)({ file: file, tags: tags })
      .then(resp => {
        setIsLoadingPageBecauseNoWorker(false);
        if (toast.isActive(key)) {
          toast.update(key, {
            duration: 10,
          });
        }
        setCountLoadingCorpuses(prev => --prev); //if?

        toast({
          description: <SuccessDescriptionToast corpus={nameOfCorpus} />,
          status: 'success',
          position: 'bottom-left',
          duration: null,
          isClosable: true,
        });
      }).catch(err => {
      if (err?.response?.status === 605) {
        let timeoutID = setTimeout(() => {
          saveGeneratedTasksAgain({ lastTimeoutID: timeoutID, key, file, tags, nameOfCorpus });
        }, 10000);
      } else {
        setIsLoadingPageBecauseNoWorker(false);
        if (toast.isActive(key)) {
          toast.update(key, {
            duration: 10,
          });
        }
        setCountLoadingCorpuses(prev => --prev);

        toast({
          description: <ErrorDescriptionToast corpus={nameOfCorpus} />,
          status: 'error',
          position: 'bottom-left',
          duration: null,
          isClosable: true,
        });
      }
    });
  };


  useEffect(() => {

    if (subject !== Subject.eng && subject !== Subject.deu && subject !== Subject.fr) {
      toast({
        title: 'Ошибка',
        description: `Невозможно удалить данный предмет (${subject}), попробуйте еще раз`,
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
      history.push('/');
    }

    variableGetTaskList(subject)().then(resp => {
      setAllTagList(resp.dataTags);
    }).catch(err => {
      if (err?.response?.status === 605) {
        handleErrorNoWorker(getTaskListAgain);
      }
    });
  }, []); //eslint-disable-line react-hooks/exhaustive-deps


  const handleCorpusChange = () => {
    if (corpusRef.current.files[0] === undefined) {
      setTags([]);
      setShowTags(false);
      setCorpus(() => '');
    } else {
      setCorpus(corpusRef.current.files[0].name);
      setShowTags(true);
    }
  };

  const handleSaveTasks = (e) => {
    setCountLoadingCorpuses(prev => ++prev);
    const nameOfCorpus = corpusRef.current.files[0].name;
    const formData = new FormData();
    formData.append('file', corpusRef.current.files[0], corpusRef.current.files[0].name);
    const key = nameOfCorpus + '-' + keyCorpus;
    setKeyCorpus(prev => ++prev);

    toast({
      id: `${key}`,
      description: <LoadingDescriptionToast corpus={nameOfCorpus} />,
      status: 'info',
      position: 'bottom-left',
      duration: null,
      isClosable: false,
    });

    variableSaveGeneratedTasks(subject)({ file: formData, tags: tags })
      .then(resp => {
        if (toast.isActive(key)) {
          toast.update(key, {
            duration: 10,
          });
        }
        setCountLoadingCorpuses(prev => --prev); //if?

        toast({
          description: <SuccessDescriptionToast corpus={nameOfCorpus} />,
          status: 'success',
          position: 'bottom-left',
          duration: null,
          isClosable: true,
        });
      })
      .catch(err => {
        if (err?.response?.status === 605) {
          setIsLoadingPageBecauseNoWorker(true);
          let timeoutID = setTimeout(() => {
            saveGeneratedTasksAgain({ lastTimeoutID: timeoutID, key, file: formData, tags: tags, nameOfCorpus });
          }, 10000);
        } else {
          if (toast.isActive(key)) {
            toast.update(key, {
              duration: 10,
            });
          }
          setCountLoadingCorpuses(prev => --prev);

          toast({
            description: <ErrorDescriptionToast corpus={nameOfCorpus} />,
            status: 'error',
            position: 'bottom-left',
            duration: null,
            isClosable: true,
          });
        }
      });

    setTags((prevTags) => {
      return [];
    });
    setCorpus('');
    corpusRef.current.value = '';
    setShowTags(false);
    setCheckedItems(() => (new Array(allTagList.length).fill(false, 0, allTagList.length)));
  };

  useEffect(() => {
    if (tags.length !== 0) {
      const isDisable = tags.find((tag1) => {
        return requiredTags.find((tag2) => {
          return tag2 === tag1;
        })
      }) === undefined;
      setIsDisabledButton(isDisable);
    }
    else {
      setIsDisabledButton(tags.length === 0);
    }
  }, [tags]);


  return (
    <>
      {isLoadingPageBecauseNoWorker ? <LoadingComponentNoWorker />
        :
        <Box>
          {(countLoadingCorpuses === 1) &&
          <Box>
            <Spinner
              thickness='4px'
              speed='1s'
              emptyColor='gray.200'
              color='blue.500'
              size='xl'
            />
            <Tooltip
              label={'Вы можете продолжать загрузку корпусов, или можете перейти к другим важным делам, Мы уведомим Вас на этой странице о статусе загрузки данного корпуса'}>
              <Text c fontWeight={'medium'}>Ровно 1 корпус загружается и скоро появится в списке...</Text>
            </Tooltip>
          </Box>}
          {(countLoadingCorpuses > 1) &&
          <Box>
            <Spinner
              thickness='4px'
              speed='1s'
              emptyColor='gray.200'
              color='blue.500'
              size='xl'
            />
            <Tooltip
              label={'Вы можете продолжать загрузку корпусов, или можете перейти к другим важным делам, Мы уведомим Вас на этой странице о статусе загрузки данного корпуса'}>
              <Text c fontWeight={'medium'}>Ровно {countLoadingCorpuses} корпуса(-ов) загружаются и скоро появятся в
                списке...</Text>
            </Tooltip>
          </Box>}
          <FormControl>
            <Stack spacing='4'>
              <FormLabel mx={'auto'} fontSize={'2xl'}>Загрузите корпус (.txt, .pdf, .docx)</FormLabel>
              <input ref={corpusRef} style={{ cursor: 'pointer' }} type={'file'} accept='.txt,.pdf,.docx'
                     onChange={handleCorpusChange} />
            </Stack>
            {showTags &&
            <Stack spacing='4'>
              <FormLabel paddingTop={'40px'} mx={'auto'} fontSize={'2xl'}>Выберите теги для данного корпуса</FormLabel>
              <FormHelperText fontSize={'lg'}>Здесь мог бы быть текст как выбирать теги</FormHelperText>
              <ModalCheckList
                setItemList={setTags}
                allItemList={allTagList}
                buttonText={'Выбрать теги'}
                titleText={'Выберите теги'}
                showAll={false}
                checkedItems={checkedItems}
                setCheckedItems={setCheckedItems}
                checkedAllItems={false}
              />
              {tags.length && tags.map((tag) => {
                return (<Box className={'tag'} key={`${tag}`}>{tag}</Box>); //onClick={(e) => handleRemoveTag(e)
              })}
                <Button type={'submit'} onClick={handleSaveTasks} isDisabled={isDisabledButton}>
                  Сохранить и сгенерировать задачи
                </Button>
              <Text fontSize={'10px'}>Выберите один или несколько обязательных тегов 'Начальные классы', 'Средние классы', 'Старшие классы'</Text>
            </Stack>
            }
          </FormControl>
          <Box position={'fixed'} right={'15%'} bottom={'50px'}>
            <Faq title={'Подсказка Upload'} text={'Что делать Upload'} />
          </Box>
        </Box>}
    </>
  );
};

export default LanguageUpload;