import React from 'react';
import { Stack, Text } from '@chakra-ui/react';

const FaqMathCheck = () => {
  return (
      <Stack spacing={'30px'}>
        <Stack spacing={'10px'}>
          <Text fontSize='xl' fontWeight={'bold'} mx={'auto'}>Запись простых чисел</Text>
          <Text fontSize={'lg'} fontWeight={'normal'}>Число записывается без пробелов, точек - одним числом</Text>
          <Text fontSize={'lg'} fontWeight={'normal'}><b>Пример:</b> 1148000</Text>
        </Stack>
        <Stack spacing={'10px'}>
          <Text fontSize='xl' fontWeight={'bold'} mx={'auto'}>Символ бесконечности и знак объединения</Text>
          <Text fontSize={'lg'} fontWeight={'normal'}>При вводе ответа на неравенства необходимо добавлять знак объединения “uu” между двумя диапазонами в скобках</Text>
          <Text fontSize={'lg'} fontWeight={'normal'}><b>Пример:</b> (-oo; 1)uu(3; +oo)</Text>
        </Stack>
        <Stack spacing={'10px'}>
          <Text fontSize='xl' fontWeight={'bold'} mx={'auto'}>Очередность ответов</Text>
          <Text fontSize={'lg'} fontWeight={'normal'}>Запись от меньшего к большему</Text>
          <Text fontSize={'lg'} fontWeight={'normal'}><b>Пояснение:</b></Text>
          <Text pl={'20px'} fontSize={'lg'} fontWeight={'normal'}>1. В уравнениях с одним неизвестным писать сначала наименьшее его значение, потом больше и тд.</Text>
          <Text pl={'20px'} fontSize={'lg'} fontWeight={'normal'}><b>Пример:</b> -1, 2, 4</Text>
          <Text pl={'20px'} fontSize={'lg'} fontWeight={'normal'}>2. В системах уравнений с несколькими неизвестными ориентируемся на наименьшее значение X и к нему соответственно подходящий Y и т. Д.
            Если Х одинаковый, то смотрим на Y и т. Д.
          </Text>
          <Text pl={'20px'} fontSize={'lg'} fontWeight={'normal'}><b>Пример:</b> (-1, 5) (1, 2); (1; 3) (1;5) </Text>
        </Stack>
        <Stack spacing={'10px'}>
          <Text fontSize='xl' fontWeight={'bold'} mx={'auto'}>Запись “одинаковых ответов”</Text>
          <Text fontSize={'lg'} fontWeight={'normal'}>В случае когда Х или Y одинаковые - записываются все ответы</Text>
          <Text fontSize={'lg'} fontWeight={'normal'}><b>Пример: (0; 6) (0; 6)</b></Text>
        </Stack>
        <Stack spacing={'10px'}>
          <Text fontSize='xl' fontWeight={'bold'} mx={'auto'}>Запись дробей</Text>
          <Text fontSize={'lg'} fontWeight={'normal'}>Дроби следует записывать с разделителем, в том числе и десятичные, выделять максимально целую часть, а остаток приводить к наименьшему знаменателю, между целой частью и остатком ставится пробел</Text>
          <Text fontSize={'lg'} fontWeight={'normal'}><b>Пример: 1 1/2, -5 6/7</b></Text>
        </Stack>
        <Stack spacing={'10px'}>
          <Text fontSize='xl' fontWeight={'bold'} mx={'auto'}>Запись нескольких ответов</Text>
          <Text fontSize={'lg'} fontWeight={'normal'}>В уравнениях с одним неизвестным и несколькими возможными вариантами ответов - писать ответы подряд через запятую и пробел, без скобок</Text>
          <Text fontSize={'lg'} fontWeight={'normal'}><b>Пример: -1, 5, -7</b></Text>
          <Text fontSize={'lg'} fontWeight={'normal'}>В системах уравнений с двумя и более неизвестными ответ по каждому значению писать в круглых скобках, отделяя между собой значения неизвестных запятыми, первым ответом всегда пишется значение X, далее идет Y, затем Z(при наличии). Круглые скобки отделяются пробелом</Text>
          <Text fontSize={'lg'} fontWeight={'normal'}><b>Пример: (2, -3, 4) (5, 1, -3)</b></Text>
          <Text fontSize={'lg'} fontWeight={'normal'}>В неравенствах, где нужно указать множество, ответ писать в круглых, либо в квадратных скобках (в зависимости от «включенности» границы в ответ), между собой значения переменных в скобках отделять точкой с запятой, две границы отделять между собой пробелом</Text>
          <Text fontSize={'lg'} fontWeight={'normal'}><b>Пример: [-4; 5) (7; 10]</b></Text>
        </Stack>
        <Stack spacing={'10px'}>
          <Text fontSize='xl' fontWeight={'bold'} mx={'auto'}>Запись сложных ответов</Text>
          <Text fontSize={'lg'} fontWeight={'normal'}>Между каждым математическим символом и цифрой/скобкой всегда ставится пробел</Text>
          <Text fontSize={'lg'} fontWeight={'normal'}><b>Пример: (-5 - 4) / (-1 1/2 + 2)</b></Text>
          <Text fontSize={'lg'} fontWeight={'normal'}>Знак умножения «*» ставится всегда, знак степени указывается как «**»</Text>
          <Text fontSize={'lg'} fontWeight={'normal'}><b>Пример: 3 * (-2) ** 2</b></Text>
        </Stack>
      </Stack>
  );
};

export default FaqMathCheck;