import React, { useState, useEffect } from 'react';
import { Box, Button, Flex, Stack, Text, Spinner } from '@chakra-ui/react';
import { MathJax, MathJaxContext } from 'better-react-mathjax';
import { mathJaxConfig } from '../../utils';

export const ExactScienceUploadPreview = ({
  data: initialData,
  isLoading,
  onSubmitForUpload,
  handleBackToEdit,
}) => {
  const [data, setData] = useState([]);
  const [isLoadingCheck, setIsLoadingCheck] = useState(false);

  useEffect(() => {
    setData(initialData);
  }, [initialData]);

  const handleCheck = () => {
    setIsLoadingCheck(true);
    onSubmitForUpload(data);
    setIsLoadingCheck(false);
  };
  console.log(data, 't21t-1');

  return (
    <React.Fragment>
      {isLoading ? (
        <Box>
          <Spinner />
          <Text>Идёт загрузка...</Text>
        </Box>
      ) : (
        <MathJaxContext config={mathJaxConfig}>
          {data.map(currentTask => {
            const cyrillicPattern = /[\u0400-\u04FF|\s]{20,}/;
            let currentTaskFormatted = currentTask.text;
            if (!currentTask.text.match(cyrillicPattern)) {
              currentTaskFormatted = '`' + currentTask.text + '`';
            }
            return (
              <Stack
                direction="column"
                spacing={4}
                key={`key-${currentTask.id}`}
              >
                <Text fontSize="2xl">Задача {currentTask.id}</Text>
                <MathJax>{currentTaskFormatted}</MathJax>
                <Stack direction="row" spacing={4} align="center">
                  <Text fontSize="xl">Ответ:</Text>
                  <Text fontSize="xl">{currentTask.right_answer}</Text>
                </Stack>
              </Stack>
            );
          })}
        </MathJaxContext>
      )}
      {!isLoading && (
        <Flex>
          <Button
            variant="ghost"
            colorScheme="teal"
            maxWidth="300px"
            mb="16px"
            onClick={handleBackToEdit}
          >
            Назад
          </Button>
          <Button
            variant="solid"
            colorScheme="teal"
            onClick={handleCheck}
            maxWidth="300px"
            isLoading={isLoadingCheck}
            mb="16px"
          >
            Сохранить шаблон
          </Button>
        </Flex>
      )}
    </React.Fragment>
  );
};
