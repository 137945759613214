import React, { useState } from 'react';
import { Flex, Text, Input, Button, Tooltip } from '@chakra-ui/react';
import { MathJax, MathJaxContext } from 'better-react-mathjax';
import { ViewOffIcon, ViewIcon } from '@chakra-ui/icons';
import { mathJaxConfig } from '../../utils';

const InlineMathJaxInput = ({
  label,
  value,
  values,
  handleUpdateValues,
  handleRemoveValue,
  handleAddValue,
  isLastValue,
  tooltipText,
}) => {
  const [showPreview, setShowPreview] = useState(false);

  return (
    <React.Fragment>
      <Flex justify="flex-start" align="center">
        <Tooltip label={tooltipText}>
          <Text mr="8px" fontSize="md">
            {label}:
          </Text>
        </Tooltip>
        <Input
          width="200px"
          ml="8px"
          padding="4px"
          textAlign="center"
          value={value.formula || ''}
          onChange={e =>
            handleUpdateValues(e.target.value, 'formula', value.id)
          }
        />
        <Button onClick={() => setShowPreview(!showPreview)} ml="16px">
          {showPreview ? <ViewOffIcon /> : <ViewIcon />}
        </Button>
        {isLastValue ? (
          <Button ml="8px" onClick={handleAddValue}>
            +
          </Button>
        ) : (
          <Button ml="8px" onClick={() => handleRemoveValue(value.id)}>
            -
          </Button>
        )}
      </Flex>
      {!!value.formula && showPreview && (
        <MathJaxContext config={mathJaxConfig}>
          <MathJax
            dynamic={true}
            hideUntilTypeset={'first'}
            renderMode={'pre'}
            text={value.formula}
            typesettingOptions={{ fn: 'asciimath2chtml' }}
          />
        </MathJaxContext>
      )}
    </React.Fragment>
  );
};

export default InlineMathJaxInput;
