import React from 'react';
import { Stack, Text } from '@chakra-ui/react';

const FaqWelcomeScreen = () => {
  return (
    <Stack spacing={'15px'}>
      <Text><b>Проверять</b> - проверка сгенерированных заданий (отмечать подходящие)</Text>
      <Text><b>Загружать</b> - создание новых шаблонов или корпусов</Text>
    </Stack>
  );
};

export default FaqWelcomeScreen;