import React, { useEffect, useState, useCallback } from 'react';
import {
  Box,
  Button,
  Checkbox,
  Flex,
  Stack,
  Input,
  Text,
  Spinner,
  createStandaloneToast,
} from '@chakra-ui/react';

import { ViewOffIcon, ViewIcon } from '@chakra-ui/icons';

import { MathJax, MathJaxContext } from 'better-react-mathjax';
import { withRouter, useHistory } from 'react-router-dom';

import API from '../api';
import { mathJaxConfig, useQuery } from '../utils';
import Faq from './FAQ';
import FaqMathCheck from './FAQ/faqMathCheck';
import { LoadingComponentNoWorker } from './LoadingComponentNoWorker';

const pageSize = 10;
const toast = createStandaloneToast();

const variableCheckExactScience = (subject) => {
  if (subject === 'physics')
    return API.checkPhysics
  else if (subject === 'essay')
    return API.checkEssay
  else
    return API.checkMath
}

const variableGetExactScience = (subject) => {
  console.log(' : 38 subject', subject);
  if (subject === 'physics') {
    return API.getPhysics;
  } else if (subject === 'essay') {
    console.log(' : 42 API.getEssay', API.getEssay);
    return API.getEssay;
  } else {
    console.log(' : 45 API.getMath', API.getMath);
    return API.getMath;
  }
}


const ExactScienceComponent = ({subject}) => {
  const query = useQuery();
  const task = query.get('task');
  const subnumber = query.get('subnumber');
  const history = useHistory();

  const [data, setData] = useState([]);
  const [page, setPage] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingCheck, setIsLoadingCheck] = useState(false);
  const [isChecked, setIsChecked] = useState(false);
  const [showPreview, setShowPreview] = useState(false);
  const [isLoadingPageBecauseNoWorker, setIsLoadingPageBecauseNoWorker] = useState(false);

  const checkMathAgain = (lastTimeoutID, dataToSend) => {
    window.clearTimeout(lastTimeoutID);
    const checkExactScience = variableCheckExactScience(subject);
    console.log(': checkExactScience', checkExactScience)
    checkExactScience({ updatedData: dataToSend, task, subnumber })
      .then(() => {
        setIsLoadingPageBecauseNoWorker(false);
        setIsLoadingCheck(false);
        setIsChecked(true);
        toast({
          title: 'Спасибо',
          description: 'Результаты проверки сохранены',
          status: 'success',
          duration: 3000,
          isClosable: true,
        });
        handleGoToNext();
      }).catch(err => {
      if (err?.response?.status === 605){
        setIsLoadingPageBecauseNoWorker(true);
        let timeoutID = setTimeout(() => {
          checkMathAgain(timeoutID, dataToSend);
        }, 10000);
      }
      else {
        setIsLoadingPageBecauseNoWorker(false);
        console.error(err);
        toast({
          title: 'Ошибка',
          description:
            'Невозможно сохранить результаты проверки, попробуйте еще раз',
          status: 'error',
          duration: 5000,
          isClosable: true,
        });
      }
    });
  }

  const fetchMath = useCallback(() => {
    const getExactScience = variableGetExactScience(subject);
    console.log(' : 1 getExactScience', getExactScience)
    getExactScience({ task, pageSize, subnumber })
      .then(resp => {
        if (resp === undefined) {
          toast({
            title: 'Ошибка',
            description: 'Невозможно загрузить задание c такими параметрами',
            status: 'error',
            duration: 5000,
            isClosable: true,
          });
          history.push('/');
        }
        setData(resp);
        setIsLoading(false);
      })
      .catch(err => {
        toast({
          title: 'Ошибка',
          description: 'Невозможно загрузить задание, попробуйте еще раз',
          status: 'error',
          duration: 5000,
          isClosable: true,
        });
        history.push('/');
      });
    console.log(' : 2 getExactScience', getExactScience);
  }, [task, history, subnumber]);

  useEffect(() => {
    setIsLoading(true);
    fetchMath();
  }, [page, fetchMath]);

  const updateMath = () => {
    const dataToSend = data.map(({ id, check_result, comment }) => ({
      id,
      check_result: !!check_result,
      comment,
    }));
    const checkExactScience = variableCheckExactScience(subject);
    checkExactScience({ updatedData: dataToSend, task, subnumber })
      .then(() => {
        setIsLoadingCheck(false);
        setIsChecked(true);
        toast({
          title: 'Спасибо',
          description: 'Результаты проверки сохранены',
          status: 'success',
          duration: 3000,
          isClosable: true,
        });
        handleGoToNext();
      })
      .catch(err => {
        if (err?.response?.status === 605){
          setIsLoadingPageBecauseNoWorker(true);
          let timeoutID = setTimeout(() => {
            checkMathAgain(timeoutID, dataToSend);
          }, 10000);
        }
        else {
          console.error(err);
          toast({
            title: 'Ошибка',
            description:
              'Невозможно сохранить результаты проверки, попробуйте еще раз',
            status: 'error',
            duration: 5000,
            isClosable: true,
          });
        }
      });
  };

  const handleGoToNext = () => {
    setPage(page + 1);
    setIsChecked(false);
  };

  const handleCheck = () => {
    setIsLoadingCheck(true);
    updateMath();
  };

  const handleUpdateField = (value, field, id) => {
    const taskToUpdateIndex = data.findIndex(task => task.id === id);
    data[taskToUpdateIndex][field] = value;
    setData([...data]);
  };
  console.log(' : currentTask data', data)
  return (
    <>
      {isLoadingPageBecauseNoWorker ? <LoadingComponentNoWorker />
        :
        <React.Fragment>
          {isLoading ? (
            <Box>
              <Spinner />
              <Text>Идёт загрузка...</Text>
            </Box>
          ) : (
            <MathJaxContext config={mathJaxConfig}>
              {data.map(currentTask => {
                const cyrillicPattern = /[\u0400-\u04FF|\s]{20,}/;
                let currentTaskFormatted = currentTask.text;
                if (!currentTask.text.match(cyrillicPattern)) {
                  currentTaskFormatted = '`' + currentTask.text + '`';
                }
                return (
                  <Stack
                    direction='column'
                    spacing={4}
                    key={`key-${currentTask.id}`}
                  >
                    <Text fontSize='2xl'>Задание {currentTask.id}</Text>
                    <MathJax>{currentTaskFormatted}</MathJax>
                    <Stack direction='row' spacing={4} align='center'>
                      <Text fontSize='xl'>Ответ:</Text>
                      <Text fontSize='xl'>{currentTask.right_answer}</Text>
                      <Checkbox
                        colorScheme='green'
                        size='lg'
                        checked={currentTask.check_result}
                        onChange={e =>
                          handleUpdateField(
                            e.target.checked,
                            'check_result',
                            currentTask.id,
                          )
                        }
                      >
                        Задание подходит
                      </Checkbox>
                    </Stack>
                    <Flex>
                      <Input
                        placeholder='Комментарий'
                        onChange={e =>
                          handleUpdateField(
                            e.target.value,
                            'comment',
                            currentTask.id,
                          )
                        }
                      />
                      <Button
                        onClick={() => setShowPreview(!showPreview)}
                        ml='16px'
                      >
                        {showPreview ? <ViewOffIcon /> : <ViewIcon />}
                      </Button>
                    </Flex>
                    {!!currentTask.comment && showPreview && (
                      <MathJax
                        dynamic={true}
                        hideUntilTypeset={'first'}
                        renderMode={'pre'}
                        text={currentTask.comment}
                        typesettingOptions={{ fn: 'asciimath2chtml' }}
                      />
                    )}
                  </Stack>
                );
              })}
              <Box position={'fixed'} right={'15%'} bottom={'50px'}>
                <Faq title={'FAQ для учеников'} text={<FaqMathCheck />} />
              </Box>
            </MathJaxContext>
          )}
          {!isLoading && (
            <Button
              variant='solid'
              colorScheme='teal'
              onClick={handleCheck}
              maxWidth='300px'
              disabled={isChecked}
              isLoading={isLoadingCheck}
              mb='16px'
            >
              Отправить результаты проверки
            </Button>
          )}
        </React.Fragment>}
    </>
  );
};

export default withRouter(ExactScienceComponent);
