import React from 'react';
import {
  Box,
  Button,
  Modal, ModalBody, ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  useDisclosure,
} from '@chakra-ui/react';

const Faq = ({title, text, }) => {
  const { isOpen, onOpen, onClose } = useDisclosure()

  return (
    <>
      <Text style={{cursor: 'pointer', textDecoration: "none",
        textShadow: '1px 1px 2px orange'}}
            fontSize={'3xl'}
            fontWeight={'bold'}
            color={'orange'}
            onClick={onOpen}><i>FAQ</i></Text>

      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>{title}</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Box>{text}</Box>
          </ModalBody>
          <ModalFooter>
            <Button colorScheme='blue' mr={3} onClick={onClose}>
              Закрыть
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  )
};

export default Faq;