import React, { useCallback, useState, useEffect } from 'react';
import {
  Box,
  Button,
  Stack,
  Input,
  Text,
  Spinner,
  createStandaloneToast,
  Checkbox,
} from '@chakra-ui/react';

import API from '../api';
import { useQuery } from '../utils';
import { withRouter, useHistory } from 'react-router-dom';

const pageSize = 5;

const toast = createStandaloneToast();

const RusComponent = () => {
  const query = useQuery();
  const task = query.get('task');
  const history = useHistory();

  const [data, setData] = useState([]);
  const [page, setPage] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingCheck, setIsLoadingCheck] = useState(false);
  const [isChecked, setIsChecked] = useState(false);

  const fetchRus = useCallback(() => {
    API.getRus({ task, pageSize })
      .then(resp => {
        setData(resp);
        setIsLoading(false);
      })
      .catch(err => {
        console.error(err);
        toast({
          title: 'Ошибка',
          description: 'Невозможно загрузить задание, попробуйте еще раз',
          status: 'error',
          duration: 5000,
          isClosable: true,
        });
        history.push('/');
      });
  }, [task, history]);

  useEffect(() => {
    setIsLoading(true);
    fetchRus();
  }, [page, fetchRus]);

  const updateRus = () => {
    const dataToSend = data.map(({ id, check_result, comment }) => ({
      id,
      check_result: !!check_result,
      comment,
    }));
    API.checkRus({ updatedData: dataToSend, task })
      .then(() => {
        setIsLoadingCheck(false);
        setIsChecked(true);
        toast({
          title: 'Спасибо',
          description: 'Результаты проверки сохранены',
          status: 'success',
          duration: 3000,
          isClosable: true,
        });
        handleGoToNext();
      })
      .catch(err => {
        console.error(err);
        toast({
          title: 'Ошибка',
          description:
            'Невозможно сохранить результаты проверки, попробуйте еще раз',
          status: 'error',
          duration: 5000,
          isClosable: true,
        });
      });
  };

  const handleGoToNext = () => {
    setPage(page + 1);
    setIsChecked(false);
  };

  const handleCheck = () => {
    setIsLoadingCheck(true);
    updateRus();
  };

  const handleUpdateField = (value, field, id) => {
    const taskToUpdateIndex = data.findIndex(task => task.id === id);
    data[taskToUpdateIndex][field] = value;
    setData([...data]);
  };

  return isLoading ? (
    <Box>
      <Spinner />
      <Text>Идёт загрузка...</Text>
    </Box>
  ) : (
    <Box mr="100px" ml="100px">
      {data.map(currentTask => (
        <Stack
          direction="column"
          spacing={4}
          mb="48px"
          key={`key-${currentTask.id}`}
        >
          <Text fontSize="2xl">Задача {currentTask.id}</Text>
          <Text align="left">{currentTask.text}</Text>
          <Stack direction="row" spacing={4} align="center" justify="center">
            <Text fontSize="xl">Ответ:</Text>
            <Text fontSize="xl" whiteSpace="pre-wrap">
              {currentTask.right_answer}
            </Text>
            <Checkbox
              colorScheme="green"
              size="lg"
              checked={currentTask.check_result}
              onChange={e =>
                handleUpdateField(
                  e.target.checked,
                  'check_result',
                  currentTask.id
                )
              }
            >
              Задание подходит
            </Checkbox>
          </Stack>
          <Input
            placeholder="Комментарий"
            onChange={e =>
              handleUpdateField(e.target.value, 'comment', currentTask.id)
            }
          />
        </Stack>
      ))}
      {!isLoading && (
        <Button
          variant="solid"
          colorScheme="teal"
          onClick={handleCheck}
          maxWidth="300px"
          disabled={isChecked}
          isLoading={isLoadingCheck}
          mb="16px"
        >
          Отправить результаты проверки
        </Button>
      )}
    </Box>
  );
};

export default withRouter(RusComponent);
