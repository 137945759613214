import React, { useEffect} from 'react';
import {
  Button, Checkbox,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay, Stack,
  useDisclosure,
} from '@chakra-ui/react';

const ModalCheckList = ({
  buttonText,
  titleText,
  setItemList,
  allItemList,
  showAll, //show Tag all
  checkedItems,
  setCheckedItems,
                          checkedAllItems,
  }) => {

  const allChecked = checkedItems.every(Boolean)
  const isIndeterminate = checkedItems.some(Boolean) && !allChecked

  const { isOpen, onOpen, onClose } = useDisclosure();

  const handleChangeItemList = (e, item, id) => {
    if (e.target.checked === true) {
      setItemList(prev => (
        [...prev, item]
      ));
    }
    else {
      setItemList(prev => prev.filter(i => i !== item));
    }
    setCheckedItems(prev => {
      let items = prev;
      items[id] = e.target.checked;
      return items;
    })
  };

  const handleChangeAll = (e) => {
    if(e.target.checked === true) {
      setItemList(() => [...allItemList]);
    }
    else {
      setItemList(() => []);
    }
    setCheckedItems(() => (new Array(allItemList.length).fill(e.target.checked, 0, allItemList.length) ))
  };

  useEffect(() => {
    if(showAll){
      if(checkedAllItems){
        setItemList(() => [...allItemList]);
        setCheckedItems(() => new Array(allItemList.length).fill(checkedAllItems, 0, allItemList.length) )
      }
       else {
        setItemList(() => []);
        setCheckedItems(() => new Array(allItemList.length).fill(checkedAllItems, 0, allItemList.length) )
      }
    }
    else {
      setItemList(() => []);
      setCheckedItems(() => new Array(allItemList.length).fill(checkedAllItems, 0, allItemList.length) )
    }                   // eslint-disable-next-line react-hooks/exhaustive-deps
  },[allItemList]) // screen check task choice, when use two modal on one screen values on different
  // modals become false or true on both modals, because [allItemList]

  return (
    <>
      <Button mb={'10px'} borderRadius={'30px'}
            fontSize={'xl'}
            fontWeight={'semibold'}
            onClick={onOpen}>{buttonText}</Button>

      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>{titleText}</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Stack>
              {showAll &&
              <Checkbox key={'all'}
                        size={'lg'}
                        isChecked={allChecked}
                        isIndeterminate={isIndeterminate}
                        onChange={(e) => {handleChangeAll(e)}}
              >
                all
              </Checkbox>}
              <Stack pl={6} mt={1} spacing={1}>
                {allItemList.map((item, id) => {

                  return(<Checkbox key={id}
                            size={'lg'}
                            isChecked={checkedItems[id]}
                            onChange={e => {
                              handleChangeItemList(e, item, id)
                            }}
                  >
                    {item}
                  </Checkbox>)
                })}
              </Stack>
            </Stack>
          </ModalBody>
          <ModalFooter>
            <Button colorScheme='blue' mr={3} onClick={onClose}>
              Выбрать
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};

export default ModalCheckList;