import React, { useEffect, useState } from 'react';
import {
  ChakraProvider,
  Button,
  Box,
  VStack,
  Grid,
  GridItem,
  Text,
  Flex,
  theme, Tooltip,
} from '@chakra-ui/react';
import { ColorModeSwitcher } from './ColorModeSwitcher';
import { BiLogOut } from 'react-icons/bi';

import {
  BrowserRouter as Router,
  Switch,
  Redirect,
  Route,
  Link as RouteLink,
  useHistory,
  useRouteMatch,
} from 'react-router-dom';
import ExactScienceComponent from './components/ExactScienceComponent';
import EssayComponent from './components/EssayComponent';
import RusComponent from './components/RusComponent';
import WelcomeScreen from './components/WelcomeScreen';
import API from './api';
import { SUBJECTS } from './utils';
import CheckTaskChoiceScreen from './components/CheckTaskChoiceScreen';
import UploadTaskChoiceScreen from './components/UploadTaskChoiceScreen';
import ExactScienceUpload from './components/ExactScienceUploadForm/ExactScienceUpload';
import EssayUpload from './components/EssayUploadForm/EssayUpload';
import { Login } from './components/Login';
import { Signup } from './components/Signup';
import LanguageUpload from './components/LanguageUploadForm/LanguageUpload';
import LanguageComponent from './components/LanguageComponent';
import DeleteTaskChoiceScreen from './components/DeleteTaskChoiceScreen';
import LanguageDelete from './components/LanguageDeleteForm/LanguageDelete';
import { LoadingComponentNoWorker } from './components/LoadingComponentNoWorker';
import PhysicsSimulationComponent from './components/PhysicsSimulationComponent';
import RenameTaskChoiceScreen from './components/RenameTaskChoiceScreen';
import EssayCheck from './components/EssayCheck/EssayCheck';

const variablePhysSim = {
  '1 График v/t': {id: 'jteavng7', link: '/check/physics?task=46&subnumber=1'},
  '2 График S/t': {id: 'zxch5rcf', link: '/check/physics?task=46&subnumber=2'},
  '3a График x/t': {id: 'jbdqysss', link: '/check/physics?task=46&subnumber=3'},
  '3b График x/t': {id: 'aypgdssb', link: '/check/physics?task=46&subnumber=4'},
  '4 График Vx/t': {id: 'gyhac3nr', link: '/check/physics?task=46&subnumber=5'},
  '5 График v/t': {id: 'hebjxuax', link: '/check/physics?task=46&subnumber=6'},
  '8 График a/t': {id: 'fbxndwnc', link: '/check/physics?task=46&subnumber=7'},
  '1 S/t График с выбором': {id: 'gmycxnd9', link: ''},
  '2 x/t График макс скорость': {id: 'ceqzmctv', link: ''},
  '5 x/t график путь': {id: 'rctuzkp6', link: ''},
  'Маятник': {id: 'zq979zeg', link: ''},
  '1 Преломление': {id: 'w4zag62c', link: ''},
  '1 Линза': {id: 'fxcqdv8g', link: ''},
  '1 Электрическая цепь': {id: 'aw28kxpd', link: '/check/physics?task=46&subnumber=8'},
  '1 Давление в трубке': {id: 'vw2xnaw7', link: '/check/physics?task=46&subnumber=9'},

}

function App() {
  const [task, setTask] = useState('');
  const [subject, setSubject] = useState('math');
  const [subnumber, setSubnumber] = useState('');
  const [user, setUser] = useState({});
  const [isAuthenticated, setIsAuthenticated] = useState(
    !!localStorage.getItem('IBLS_token')
  );
  const [subTask, setSubTask] = useState([]);
  const [isUniqueTaskEng, setIsUniqueTaskEng] = useState(true);
  const [isSearchByTags, setIsSearchByTags] = useState(false);
  const [isLoadingPageBecauseNoWorker, setIsLoadingPageBecauseNoWorker] = useState(false);

  const [physSim, setPhysSim] = useState('');
  const [physSimId, setPhysSimId] = useState('');
  const [physSimLink, setPhysSimLink] = useState('');

  let history = useHistory();

  const handleSubmitCheck = () => {
    console.log('SUBJECT', subject);
    if (subject === 'physics-sim') {
      setPhysSimId(variablePhysSim[physSim].id);
      setPhysSimLink(variablePhysSim[physSim].link);
      history.push(`/check/simulation/${physSim}`);
    }
    else {
      let queryParams = `task=${task}`;
      console.log('queryParams', queryParams);
      console.log('subnumber', subnumber);
      if (subnumber) {
        queryParams = queryParams + `&subnumber=${subnumber}`;
      }
      if(subject === 'english' || subject === 'deutsch' || subject === 'francais'){
        queryParams = queryParams + `&unique=${isUniqueTaskEng?'unique':''}&subtask=${subTask}&isSearchByTags=${isSearchByTags}`
      }
      console.log(`/check/${subject}?${queryParams}`)
      history.push(`/check/${subject}?${queryParams}`);
    }
  };

  const handleSubmitUpload = () => {
    let queryParams = `task=${task}`;
    if (subnumber) {
      queryParams = queryParams + `&subnumber=${subnumber}`;
    }
    if(subject === 'english' || subject === 'deutsch' || subject === 'francais')
      history.push(`/upload/${subject}`);
    else
      history.push(`/upload/${subject}?${queryParams}`);
  };

  const handleSubmitDelete = () => {
    history.push(`/delete/${subject}`);
  };

  const getCurrentUserAgain = (lastTimeoutID) => {
    window.clearTimeout(lastTimeoutID);
    API.getCurrentUser().then(resp => {
      setIsLoadingPageBecauseNoWorker(false);
      setUser(resp);
    }).catch(err => {
      if (err?.response?.status === 605) {
        let timeoutID = setTimeout(() => {
          getCurrentUserAgain(timeoutID);
        }, 10000);
      } else {
        setIsLoadingPageBecauseNoWorker(false);
      }
    });
  }

  useEffect(() => {
    if (isAuthenticated) {
      API.getCurrentUser().then(res => {
        setUser(res);
      }).catch(err => {
        if (err?.response?.status === 605) {
          setIsLoadingPageBecauseNoWorker(true);
          let timeoutID = setTimeout(() => {
            getCurrentUserAgain(timeoutID);
          }, 10000);
        }
      });
    }
  }, [isAuthenticated]); // eslint-disable-line react-hooks/exhaustive-deps

  const handleLogout = () => {
    localStorage.removeItem('IBLS_token');
    setIsAuthenticated(false);
    setUser({});
    history.push('/login');
  };

  const isHome = useRouteMatch({ path: '/home', strict: true });

  return (
    <React.Fragment>
      <Box textAlign="center" fontSize="xl">
        <Grid minH="100vh" p={3}>
          <Grid templateColumns="repeat(7, 1fr)" gap={6}>
            {isAuthenticated && (
              <React.Fragment>
                <GridItem colSpan={1}>
                  {!isHome && (
                    <RouteLink to="/home">
                      <Button>в начало</Button>
                    </RouteLink>
                  )}
                </GridItem>
                <GridItem colSpan={1}>
                  <Text
                    height="40px"
                    display="flex"
                    alignItems="center"
                    fontSize="sm"
                  >
                    Предмет: {SUBJECTS[subject] || '_'}
                  </Text>
                </GridItem>
                <GridItem colSpan={1}>
                  <Tooltip label={`Задание: ${task || '_'}.${subnumber || '_'}`}>
                    <Text
                      height="40px"
                      display="flex"
                      alignItems="center"
                      fontSize="sm"
                      isTruncated
                      maxW={'100px'}
                    >
                      Задание: {task || '_'}.{subnumber || '_'}
                    </Text>
                  </Tooltip>
                </GridItem>
                <GridItem colSpan={2} colStart={5}>
                  <Flex>
                    <Box
                      height="40px"
                      fontSize="sm"
                      display="flex"
                      alignItems="center"
                    >
                      {user.name}
                      <Text color="grey" ml="4px">
                        {user.email}
                      </Text>
                    </Box>
                    <Button
                      variant="ghost"
                      ml="4px"
                      as="a"
                      cursor="pointer"
                      onClick={handleLogout}
                    >
                      <BiLogOut />
                    </Button>
                  </Flex>
                </GridItem>
              </React.Fragment>
            )}

            <GridItem colSpan={1} colStart={7}>
              <ColorModeSwitcher justifySelf="flex-end" textAlign="right" />
            </GridItem>
          </Grid>
          {isLoadingPageBecauseNoWorker ? <LoadingComponentNoWorker />
            :
          <VStack spacing={1}>
            <Switch>
              <Route exact path="/">
                {isAuthenticated ? (
                  <Redirect to="/home" />
                ) : (
                  <Redirect to="/login" />
                )}
              </Route>
              <Route exact path="/signup">
                <Signup />
              </Route>
              <Route exact path="/login">
                <Login setIsAuthenticated={setIsAuthenticated} />
              </Route>
              <Route exact path="/home">
                {isAuthenticated ? <WelcomeScreen
                  setSubject={setSubject}
                  setSubnumber={setSubnumber}
                  setTask={setTask}
                /> : <Redirect to="/login" />}
              </Route>
              <Route exact path="/check">
                {isAuthenticated ? (
                  <CheckTaskChoiceScreen
                    subTask={subTask}
                    setSubTask={setSubTask}
                    isUniqueTaskEng={isUniqueTaskEng}
                    setIsUniqueTaskEng={setIsUniqueTaskEng}
                    task={task}
                    setTask={setTask}
                    subject={subject}
                    setSubject={setSubject}
                    onSubmit={handleSubmitCheck}
                    subnumber={subnumber}
                    setSubnumber={setSubnumber}
                    buttonText="Перейти к проверке"
                    isSearchByTags={isSearchByTags}
                    setIsSearchByTags={setIsSearchByTags}
                    physSim={physSim}
                    setPhysSim={setPhysSim}
                  />
                ) : (
                  <Redirect to="/login" />
                )}
              </Route>
              <Route exact path="/upload">
                {isAuthenticated ? (
                  <UploadTaskChoiceScreen
                    task={task}
                    setTask={setTask}
                    subject={subject}
                    setSubject={setSubject}
                    onSubmit={handleSubmitUpload}
                    subnumber={subnumber}
                    setSubnumber={setSubnumber}
                    buttonText="Перейти к загрузке"
                  />
                ) : (
                  <Redirect to="/login" />
                )}
              </Route>
              <Route path={['/check/math', '/check/physics']}>
                {isAuthenticated ? <ExactScienceComponent subject={subject} /> : <Redirect to="/login" />}
              </Route>
              <Route path={['/check/essay']}>
                {isAuthenticated ? <EssayComponent subject={subject} /> : <Redirect to="/login" />}
              </Route>
              <Route path="/check/simulation">
                {isAuthenticated ? <PhysicsSimulationComponent setSubject={setSubject} id={physSimId} link={physSimLink}/> : <Redirect to="/login" />}
              </Route>
              <Route path="/check/rus">
                {isAuthenticated ? <RusComponent /> : <Redirect to="/login" />}
              </Route>
              <Route path={["/check/english", "/check/deutsch", "/check/francais"]}>
                {isAuthenticated ? <LanguageComponent subject={subject} /> : <Redirect to="/login" />}
              </Route>
              <Route path={["/upload/math", "/upload/physics"]}>
                {isAuthenticated ? <ExactScienceUpload subject={subject} /> : <Redirect to="/login" />}
              </Route>
              <Route path={["/upload/essay"]}>
                {isAuthenticated ? <EssayUpload subject={subject} /> : <Redirect to="/login" />}
              </Route>
              <Route path={['/upload/english', "/upload/deutsch", "/upload/francais"]}>
                {isAuthenticated ? <LanguageUpload
                  subject={subject}
                  setSubject={setSubject}
                  tags={subnumber}
                  setTags={setSubnumber}
                  setCorpus={setTask}
                /> : <Redirect to="/login" />}
              </Route>
              <Route exact path="/delete">
                {isAuthenticated ? <DeleteTaskChoiceScreen
                  subject={subject}
                  setSubject={setSubject}
                  buttonText={'Перейти к удалению'}
                  onSubmit={handleSubmitDelete}
                  task={task}
                  setTask={setTask}
                /> : <Redirect to="/login" />}
              </Route>
              <Route path={["/delete/english", "/delete/deutsch", "/delete/francais"]}>
                {isAuthenticated ? <LanguageDelete subject={subject} /> : <Redirect to="/login" />}
              </Route>
              <Route exact path='/rename'>
                {isAuthenticated ? <RenameTaskChoiceScreen subject={subject} setSubject={setSubject} task={task} setTask={setTask} /> : <Redirect to="/login" />}
              </Route>
              <Route path="*">
                <Redirect to="/home" />
              </Route>
            </Switch>
          </VStack>}
        </Grid>
      </Box>
    </React.Fragment>
  );
}

const AppWrapper = () => (
  <ChakraProvider theme={theme}>
    <Router>
      <App />
    </Router>
  </ChakraProvider>
);

export default AppWrapper;
