import React, { useEffect, useState } from 'react';
import { Button, Box, Select, Text, Flex, createStandaloneToast } from '@chakra-ui/react';
import Faq from './FAQ';
import API from '../api';

const toast = createStandaloneToast();

const variableGetTaskList = (sub) => {
  if (sub === 'math')
    return API.getMathTasksList;
  else if (sub === 'essay')
    return API.getEssayTasksList;
  else
    return API.getPhysicsTasksList;
};

const variableDeleteTheme = (sub) => {
  if (sub === 'math')
    return API.deleteMathTheme;
  else if (sub === 'essay')
    return API.deleteEssayTheme;
  else
    return API.deletePhysicsTheme;
};

const DeleteTaskChoiceScreen = ({
                                  subject,
                                  setSubject,
                                  buttonText,
                                  onSubmit,
                                  task,
                                  setTask,
                                }) => {
  const [taskList, setTaskList] = useState([]);
  const [refresh, setRefresh] = useState(false);

  useEffect(() => {
    setSubject('math');
    setTask('')
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    const getTasksList = variableGetTaskList(subject);
    getTasksList().then(resp => {
      setTaskList(() => {
        return Object.keys(resp.body).map((key) => {
          return { [key]: resp.body[key] }
        }).sort((a, b) => {
          if (Object.values(a)[0].toLowerCase() < Object.values(b)[0].toLowerCase()) {
            return -1;
          }
          if (Object.values(a)[0].toLowerCase() > Object.values(b)[0].toLowerCase()) {
            return 1;
          }
          // a должно быть равным b
          return 0;
        });
      })
    })
  }, [subject, refresh]) // eslint-disable-line react-hooks/exhaustive-deps

  const handleClick = () => {
    if (subject === 'math' || subject === 'physics' || subject === 'essay') {
      const deleteTheme = variableDeleteTheme(subject);
      const name = Object.values(taskList.find(item => {
        const [key, value] = Object.entries(item)[0];
        if (key === task) {
          return true
        }
      }))[0];
      deleteTheme({name}).then(() => {
        toast({
          title: 'Выполнено',
          description: `Тема ${name} была удалена`,
          status: 'success',
          position: 'bottom-left',
          duration: 3000,
          isClosable: true,
        });
        setRefresh((prev) => !prev);
      })
        .catch(() => {
          toast({
            title: 'Ошибка',
            description: `Не удалось удалить тему ${name}`,
            status: 'error',
            position: 'bottom-left',
            duration: 3000,
            isClosable: true,
          });
        })
    }
    else {
      onSubmit();
    }
  }

  return (
    <Box mr='auto' ml='auto'>
      <Select width='400px' mr='auto' ml='auto' mb="40px"
              onChange={e => {
                setSubject(e.target.value);
              }}
              value={subject}
      >
        <option value="math">Математика</option>
        <option value="essay">Изложение</option>
        <option value="physics">Физика</option>
        <option value="english">Английский язык</option>
        <option value="deutsch">Немецкий язык</option>
        <option value="francais">Французский язык</option>
      </Select>
      {(subject === 'math' || subject === 'physics' || subject === 'essay') &&
        <Flex mb="20px">
          <Text mr='16px'>Задание:</Text>
          <Select
            onChange={e => {
              setTask(e.target.value);
            }}
            value={task}
            placeholder='Выберите задание'
          >
            {taskList.map((task, id) => {
              if (typeof task === 'object') {
                return (
                  <option value={Object.keys(task)[0]} key={`task-option-${id}`}>
                    {Object.values(task)[0]}
                  </option>
                )
              }
            })}
          </Select>
        </Flex>
      }
        <Button
          onClick={handleClick}
          mt='40px'
          variant='solid'
        >
          {subject === 'math' || subject === 'physics' || subject === 'essay' ? 'Удалить тему' : buttonText}
        </Button>
      <Box position={'fixed'} right={'15%'} bottom={'50px'}>
        <Faq title={'Подсказка Delete'} text={'Что делать Delete'}/>
      </Box>
    </Box>
  );
};

export default DeleteTaskChoiceScreen;
