import React, { useState } from 'react';
import {
  Button,
  Input,
  InputGroup,
  InputRightElement,
  Stack,
  createStandaloneToast,
  Link,
  FormErrorMessage,
  FormLabel,
  FormControl,
} from '@chakra-ui/react';
import API from '../api';

import { useHistory } from 'react-router-dom';
import { LoadingComponentNoWorker } from './LoadingComponentNoWorker';

const toast = createStandaloneToast();

export const Login = ({ setIsAuthenticated }) => {
  const [login, setLogin] = React.useState('');
  const [password, setPassword] = React.useState('');
  const [show, setShow] = React.useState(false);
  const [errorNoLogin, setErrorNoLogin] = React.useState(false);
  const [errorNoPassword, setErrorNoPassword] = React.useState(false);
  const [isLoadingPageBecauseNoWorker, setIsLoadingPageBecauseNoWorker] = useState(false);

  let history = useHistory();

  const handleClick = () => setShow(!show);

  const authAgain = (lastTimeoutID) => {
    window.clearTimeout(lastTimeoutID);
    API.auth({username: login, password}).then(resp => {
      setIsLoadingPageBecauseNoWorker(false);
      localStorage.setItem('IBLS_token', resp.access_token);
      setIsAuthenticated(true);
      history.push('/home');
    }).catch(err => {
      if (err?.response?.status === 605) {
        let timeoutID = setTimeout(() => {
          authAgain(timeoutID);
        }, 10000);
      } else {
        setIsLoadingPageBecauseNoWorker(false);
        console.error(err);
        toast({
          title: 'Ошибка',
          description: 'Что-то пошло не так, попробуйте еще раз',
          status: 'error',
          duration: 5000,
          isClosable: true,
        });
      }
    });
  }

  const handleLogin = e => {
    e.preventDefault();
    setErrorNoLogin(false);
    setErrorNoPassword(false);
    if (!login) {
      setErrorNoLogin(true);
    }
    if (!password) {
      setErrorNoPassword(true);
    }

    if (login && password) {
      API.auth({ username: login, password })
        .then(res => {
          localStorage.setItem('IBLS_token', res.access_token);
          setIsAuthenticated(true);
          history.push('/home');
        })
        .catch(err => {
          if (err.response?.status === 605) {
            setIsLoadingPageBecauseNoWorker(true);
            let timeoutID = setTimeout(() => {
              authAgain(timeoutID);
            }, 10000);
          } else {
            console.error(err);
            toast({
              title: 'Ошибка',
              description: 'Что-то пошло не так, попробуйте еще раз',
              status: 'error',
              duration: 5000,
              isClosable: true,
            });
          }
        });
    }
  };

  return (
    <>
      {isLoadingPageBecauseNoWorker ? <LoadingComponentNoWorker />
        :
    <form onSubmit={handleLogin}>
      <Stack width="400px">
        <FormControl isInvalid={errorNoLogin && !login}>
          <FormLabel>Email:</FormLabel>
          <Input
            placeholder="Введите email"
            value={login}
            type="email"
            onChange={e => setLogin(e.target.value)}
          />
          {errorNoLogin && !login && (
            <FormErrorMessage>
              Ошибка: поле не может быть пустым
            </FormErrorMessage>
          )}
        </FormControl>
        <FormControl isInvalid={errorNoLogin && !login}>
          <FormLabel>Пароль:</FormLabel>
          <InputGroup size="md">
            <Input
              pr="4.5rem"
              type={show ? 'text' : 'password'}
              placeholder="Введите пароль"
              value={password}
              onChange={e => setPassword(e.target.value)}
            />
            <InputRightElement width="4.5rem">
              <Button h="1.75rem" mr="8px" size="xs" onClick={handleClick}>
                {show ? 'Спрятать' : 'Показать'}
              </Button>
            </InputRightElement>
          </InputGroup>
          {errorNoPassword && !password && (
            <FormErrorMessage>
              Ошибка: поле не может быть пустым{' '}
            </FormErrorMessage>
          )}
        </FormControl>
        <Button type="submit" width="100%" variant="solid">
          Войти
        </Button>
        <Link href="/signup" fontSize="sm">
          Зарегистрироваться
        </Link>
      </Stack>
    </form>}
    </>
  );
};
