import axios from 'axios';

const API_BASE_URL = process.env.REACT_APP_HOST;

const getAPIClient = () =>
  axios.create({
    baseURL: API_BASE_URL,
    withCredentials: true,
    credentials: true,
    headers: {
      Authorization: `JWT ${localStorage.getItem('IBLS_token')}`,
    },
  });

const API = {
  auth: async dataToSend => {
    const { data } = await getAPIClient().post('/auth', dataToSend);
    return data;
  },

  signup: async dataToSend => {
    const formData = new FormData();
    for (var key in dataToSend) {
      formData.append(key, dataToSend[key]);
    }
    const { data } = await axios.post(`${API_BASE_URL}/auth/signup`, formData, {
      headers: { 'Content-Type': 'multipart/form-data', demo: 'protected' },
    });
    return data;
  },

  getMath: async ({ task, pageSize, subnumber }) => {
    const { data } = await getAPIClient().get(
      '/task-generator/math/generate-tasks-check',
      {
        params: { number: task, n: pageSize, subnumber },
      }
    );
    return data.data;
  },
  
  getEssay: async ({ task, pageSize, subnumber }) => {
    const { data } = await getAPIClient().get(
      '/task-generator/essay/generate-tasks-check',
      {
        params: { number: task, n: pageSize, subnumber },
      }
    );
    return data.data;
  },
  
  getPhysics: async ({ task, pageSize, subnumber }) => {
    const { data } = await getAPIClient().get(
      '/task-generator/physics/generate-tasks-check',
      {
        params: { number: task, n: pageSize, subnumber },
      }
    );
    return data.data;
  },

  getEnglish: async ({ corpus, pageSize, parts, tags, unique }) => {
    const { data } = await getAPIClient().get(
      '/task-generator/english/load',
      {
        params: { corpus: corpus, parts: parts, count: pageSize, tags: tags, unique: unique }, // список корпусов,
      },
    );
    return data.data;
  },

  getDeutsch: async ( {corpus, pageSize, parts, tags, unique} ) => {
    const {data} = await getAPIClient().get(
      '/task-generator/deutsch/load',
      {
        params: { corpus: corpus, parts: parts, count: pageSize, tags: tags, unique: unique}, // список корпусов,
      }
    );
    return data.data;
  },

  getFrancais: async ( {corpus, pageSize, parts, tags, unique} ) => {
    const {data} = await getAPIClient().get(
      '/task-generator/francais/load',
      {
        params: { corpus: corpus, parts: parts, count: pageSize, tags: tags, unique: unique}, // список корпусов,
      }
    );
    return data.data;
  },


  checkMath: async ({ updatedData, task, subnumber }) => {
    const { data } = await getAPIClient().post(
      '/task-generator/math/upload-tasks-check',
      updatedData,
      {
        params: { number: task, subnumber },
      }
    );

    return data;
  },

  checkPhysics: async ({ updatedData, task, subnumber }) => {
    const { data } = await getAPIClient().post(
      '/task-generator/physics/upload-tasks-check',
      updatedData,
      {
        params: { number: task, subnumber },
      }
    );

    return data;
  },

  checkEssay: async ({ updatedData, task, subnumber }) => {
    const { data } = await getAPIClient().post(
      '/task-generator/essay/upload-tasks-check',
      updatedData,
      {
        params: { number: task, subnumber },
      }
    );

    return data;
  },

  checkEssayResults: async ({ updatedData, task, subnumber, essay }) => {
    const { data } = await getAPIClient().post(
      '/task-generator/essay_predict',
      updatedData,
      {
        params: { number: task, subnumber },
      }
    );

    return data;
  },

  checkEnglish: async ( { updateData: updatedData } ) => {
    const {data} = await getAPIClient().post(
      '/task-generator/english/load',
      {
        updatedData
      }
    );
    return data.data;
  },

  checkDeutsch: async ( { updateData: updatedData } ) => { // corpus, parts, tags?
    const {data} = await getAPIClient().post(
      '/task-generator/deutsch/load',
      {
        updatedData
      }
    );
    return data.data;
  },

  checkFrancais: async ( { updateData: updatedData } ) => {
    const {data} = await getAPIClient().post(
      '/task-generator/francais/load',
      {
        updatedData
      }
    );
    return data.data;
  },

  getRus: async ({ task, pageSize }) => {
    const { data } = await getAPIClient().get(
      '/task-generator/ege-russian/generate-tasks-check',
      {
        params: { number: task, n: pageSize },
      }
    );
    return data;
  },
  checkRus: async ({ updatedData, task }) => {
    const { data } = await getAPIClient().post(
      '/task-generator/ege-russian/upload-tasks-check',
      updatedData,
      {
        params: { number: task },
      }
    );

    return data;
  },
  getMathTasksList: async () => {
    const { data } = await getAPIClient().get(
      '/task-generator/math_cfgs_names'
    );
    return data;
  },

  getPhysicsTasksList: async () => {
    const { data } = await getAPIClient().get(
      '/task-generator/cfgs_names'
    );
    return data;
  },

  getEssayTasksList: async () => {
    const { data } = await getAPIClient().get(
      '/task-generator/essay_cfgs_names'
    );
    return data;
  },

  getRusTasksList: async () => {
    const {data} = await getAPIClient().get(
       '/task-generator/ege-russian/task-numbers'
    );
    return data;
  },
  getEnglishTasksList: async () => {
    const  { data }  = await getAPIClient().get(
      '/task-generator/english/get_eng_corps_and_tags'
    );
    return {data: data.data_corpuses, dataTags: data.data_tags};
  },
  getDeutschTasksList: async () => {
    const  { data }  = await getAPIClient().get(
      '/task-generator/deutsch/get_de_corps_and_tags'
    );
    return {data: data.data_corpuses, dataTags: data.data_tags};
  },
  getFrancaisTasksList: async () => {
    const  { data }  = await getAPIClient().get(
      '/task-generator/francais/get_fr_corps_and_tags'
    );
    return {data: data.data_corpuses, dataTags: data.data_tags};
  },

  getMathSubnumbers: async ({ task }) => {
    const { data } = await getAPIClient().get(
      '/task-generator/math/task-subnumbers',
      { params: { number: task } }
    );
    return data;
  },

  getPhysicsSubnumbers: async ({ task }) => {
    const { data } = await getAPIClient().get(
      '/task-generator/physics/task-subnumbers',
      { params: { number: task } }
    );
    return data;
  },

  getEssaySubNumbers: async ({ task }) => {
    const { data } = await getAPIClient().get(
      '/task-generator/essay/task-subnumbers',
      { params: { number: task } }
    );
    return data;
  },

  getEnglishSubNumbers: async () => {
    const  { data }  = await getAPIClient().get(
      '/task-generator/english/get_eng_parts'
    );
    return data;
  },

  getDeutschSubNumbers: async () => {
    const  { data }  = await getAPIClient().get(
      '/task-generator/deutsch/get_de_parts'
    );
    return data;
  },

  getFrancaisSubNumbers: async () => {
    const  { data }  = await getAPIClient().get(
      '/task-generator/francais/get_fr_parts'
    );
    return data;
  },

  // getEnglishInner: async () => {
  //   const {data} = await getAPIClient().get(
  //     '/english/load_inner'
  //   );
  //   return data;
  // },

  getCurrentUser: async () => {
    const { data } = await getAPIClient().get('/me');
    return data;
  },
  logout: async () => {
    const { data } = await getAPIClient().get('/logout');
    return data;
  },

  generateTasks: async ({ formData, task, subnumber }) => {
    // /math/generate-tasks-by-form
    const { data } = await getAPIClient().post(
      '/task-generator/math/generate-tasks-by-form',
      formData,
      {
        params: {
          number: task,
          ...(subnumber && subnumber !== 'new' && { subnumber }),
        },
      }
    );

    return data;
  },

  generateTasksEssay: async ({ formData, task, subnumber }) => {
    const { data } = await getAPIClient().post(
      '/task-generator/essay/generate-tasks-by-form',
      formData,
      {
        params: {
          number: task,
          ...(subnumber && subnumber !== 'new' && { subnumber }),
        },
      }
    );

    return data;
  },

  generateTasksPhysics: async ({ formData, task, subnumber }) => {
    const { data } = await getAPIClient().post(
      '/task-generator/physics/generate-tasks-by-form',
      formData,
      {
        params: {
          number: task,
          ...(subnumber && subnumber !== 'new' && { subnumber }),
        },
      }
    );

    return data;
  },

  saveGeneratedTasks: async ({ formData, task, subnumber }) => {
    const { data } = await getAPIClient().post(
      '/task-generator/math/insert-tasks',
      formData,
      {
        params: {
          number: task,
          ...(subnumber && subnumber !== 'new' && { subnumber }),
        },
      }
    );

    return data;
  },

  saveGeneratedTasksEssay: async ({ formData, task, subnumber }) => {
    const { data } = await getAPIClient().post(
      '/task-generator/essay/insert-tasks',
      formData,
      {
        params: {
          number: task,
          ...(subnumber && subnumber !== 'new' && { subnumber }),
        },
      }
    );

    return data;
  },

  saveGeneratedTasksPhysics: async ({ formData, task, subnumber }) => {
    const { data } = await getAPIClient().post(
      '/task-generator/physics/insert-tasks',
      formData,
      {
        params: {
          number: task,
          ...(subnumber && subnumber !== 'new' && { subnumber }),
        },
      }
    );

    return data;
  },

  saveGeneratedTasksEnglish: async ({file, tags}) => {
    const { data } = await getAPIClient().post(
      '/task-generator/english/upload',
      file,
      {
        params: {tags: tags},
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      }
    );
    return data;
  },

  saveGeneratedTasksDeutsch: async ({file, tags}) => {
    const { data } = await getAPIClient().post(
      '/task-generator/deutsch/upload',
      file,
      {
        params: {tags: tags},
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      }
    );
    return data;
  },

  saveGeneratedTasksFrancais: async ({file, tags}) => {
    const { data } = await getAPIClient().post(
      '/task-generator/francais/upload',
      file,
      {
        params: {tags: tags},
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      }
    );
    return data;
  },

  deleteEnglishCorpuses: async ({corpus}) => {
    const { data } = await getAPIClient().post(
      '/task-generator/english/delete',
      corpus,
      {
        params: {corpus: corpus},
      }

    );
  return data;
  },

  deleteDeutschCorpuses: async ({corpus}) => {
    const { data } = await getAPIClient().post(
      '/task-generator/deutsch/delete',
      corpus,
      {
        params: {corpus: corpus},
      }

    );
    return data;
  },

  deleteFrancaisCorpuses: async ({corpus}) => {
    const { data } = await getAPIClient().post(
      '/task-generator/francais/delete',
      corpus,
      {
        params: {corpus: corpus},
      }

    );
    return data;
  },


  getTemplateConfig: async ({ task, subnumber }) => {
    const { data } = await getAPIClient().post(
      '/task-generator/math/get-task-config',
      {},
      { params: { number: task, subnumber } }
    );
    return data;
  },

  getTemplateConfigPhysics: async ({ task, subnumber }) => {
    const { data } = await getAPIClient().post(
      '/task-generator/physics/get-task-config',
      {},
      { params: { number: task, subnumber } }
    );
    return data;
  },

  getTemplateConfigEssay: async ({ task, subnumber }) => {
    const { data } = await getAPIClient().post(
      '/task-generator/essay/get-task-config',
      {},
      { params: { number: task, subnumber } }
    );
    return data;
  },

  createNewPhysicsTheme: async ({ name }) => {
    const { data } = await getAPIClient().post(
      '/task-generator/config_new',
      {name}
    );
    return data;
  },

  createNewMathTheme: async ({ name }) => {
    const { data } = await getAPIClient().post(
      '/task-generator/math_config_new',
      {name}
    );
    return data;
  },

  renamePhysicsTheme: async ({ old_name, new_name }) => {
    const { data } = await getAPIClient().post(
      `/task-generator/rename_cfgs?old_name=${old_name}&new_name=${new_name}`
    );
    return data;
  },

  renameMathTheme: async ({ old_name, new_name }) => {
    const { data } = await getAPIClient().post(
      `/task-generator/math_rename_cfgs?old_name=${old_name}&new_name=${new_name}`
    );
    return data;
  },

  renameEssayTheme: async ({ old_name, new_name }) => {
    const { data } = await getAPIClient().post(
      `/task-generator/essay_rename_cfgs?old_name=${old_name}&new_name=${new_name}`
    );
    return data;
  },

  deletePhysicsTheme: async ({ name }) => {
    const { data } = await getAPIClient().post(
      `/task-generator/delete_cfgs?name=${name}`
    );
    return data;
  },

  deleteEssayTheme: async ({ name }) => {
    const { data } = await getAPIClient().post(
      `/task-generator/essay_delete_cfgs?name=${name}`
    );
    return data;
  },

  deleteMathTheme: async ({ name }) => {
    const { data } = await getAPIClient().post(
      `/task-generator/math_delete_cfgs?name=${name}`
    );
    return data;
  },

  newPhysicsTheme: async ({ name }) => {
    const { data } = await getAPIClient().post(
      `/task-generator/config_new?name=${name}`
    );
    return data;
  },

  newEssayTheme: async ({ name }) => {
    const { data } = await getAPIClient().post(
      `/task-generator/essay_config_new?name=${name}`
    );
    return data;
  },

  newMathTheme: async ({ name }) => {
    const { data } = await getAPIClient().post(
      `/task-generator/math_config_new?name=${name}`
    );
    return data;
  },
}

export default API;
