import React, { useState, useEffect } from 'react';
import { Text } from '@chakra-ui/react';

export const ErrorDescriptionToast401 = ({exercise}) => {
  return (
    <Text color={'black'} width={'350px'} fontSize={'md'}>Невозможно загрузить шаблон <b>{exercise}</b>, ошибка в открывающих/закрывающих скобках. Пожалуйста, попробуйте еще раз</Text>
  );
};

export const ErrorDescriptionToast402 = ({exercise}) => {
  return (
    <Text color={'black'} width={'350px'} fontSize={'md'}>Невозможно загрузить шаблон <b>{exercise}</b>, присутствуют кириллические символы. Пожалуйста, попробуйте еще раз</Text>
  );
};

export const ErrorDescriptionToast403 = ({exercise}) => {
  return (
    <Text color={'black'} width={'350px'} fontSize={'md'}>Невозможно загрузить шаблон <b>{exercise}</b>, присутствуют знаки равенства/неравенства в формуле ответа. Пожалуйста, попробуйте еще раз</Text>
  );
};

export const ErrorDescriptionToastAll = ({exercise}) => {
  return (
    <Text color={'black'} width={'350px'} fontSize={'md'}>Невозможно загрузить шаблон <b>{exercise}</b>. Пожалуйста, попробуйте еще раз</Text>
  );
};



export const ErrorDescriptionToast = ({exercise}) => {
  return (
    <Text color={'black'} width={'350px'} fontSize={'md'}>Невозможно загрузить шаблон <b>{exercise}</b>. Пожалуйста, попробуйте еще раз</Text>
  );
};

export const SuccessDescriptionToast = ({exercise}) => {
  return (
    <Text color={'black'} width={'350px'} fontSize={'md'}>Шаблон (<b>{exercise}</b>) был успешно сохранён</Text>
  );
};

export const LoadingDescriptionToast = ({exercise}) => {
  return (
    <Text color={'black'} width={'350px'} fontSize={'md'}>Шаблон (<b>{exercise}</b>) сохраняется</Text>
  );
};