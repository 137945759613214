import React from 'react';
import { Text } from '@chakra-ui/react';

export const ErrorDescriptionToast = ({corpus}) => {
  return (
    <Text color={'black'} width={'350px'} fontSize={'md'}>Корпус <b>{corpus}</b> не был загружен. Пожалуйста, попробуйте еще раз</Text>
  );
};

export const SuccessDescriptionToast = ({corpus}) => {
  return (
    <Text color={'black'} width={'350px'} fontSize={'md'}>Корпус (<b>{corpus}</b>) был успешно загружен</Text>
  );
};

export const LoadingDescriptionToast = ({corpus}) => {
  return (
    <Text color={'black'} width={'350px'} fontSize={'md'}>Корпус (<b>{corpus}</b>) загружается и скоро появится в списке!</Text>
  );
};
