import React, { useEffect } from 'react';
import { Box, Button, Flex, Stack } from '@chakra-ui/react';
import { useHistory } from 'react-router-dom';
import Faq from './FAQ';
import FaqWelcomeScreen from './FAQ/faqWelcomeScreen';

const WelcomeScreen = ({setSubject, setTask, setSubnumber}) => {

  useEffect(() => {
    setSubject(() => 'math');
    setTask('');
    setSubnumber('');
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  const history = useHistory();
  return (
    <Stack spacing={'24px'} textAlign={'center'} alignItems="center">
      <Flex width="400px" justifyContent="center" alignItems="center">
        <Button
          onClick={() => history.push('/check')}
          mt="40px"
          variant="solid"
          mr="24px"
        >
          Проверять
        </Button>

        <Button onClick={() => history.push('/upload')} mt="40px" variant="solid">
          Загружать
        </Button>
      </Flex>
      <Flex width="400px" justifyContent="center" alignItems="center">
        <Button
          onClick={() => history.push('/rename')}
          variant="solid"
          mr="24px"
        >
          Переименовать
        </Button>

        <Button onClick={() => history.push('/delete')} variant="solid">
          Удалять
        </Button>
      </Flex>

      <Box position={'fixed'} right={'15%'} bottom={'50px'}>
        <Faq title={'Мы постараемся помочь Вам, на всех этапах'} text={<FaqWelcomeScreen/>} />
      </Box>
    </Stack>
  );
};

export default WelcomeScreen;
