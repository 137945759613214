import React from 'react';
import { Text } from '@chakra-ui/react';

export const ErrorDescriptionToast = ({corpus}) => {
  return (
    <Text color={'black'} width={'350px'} fontSize={'md'}>Корпуса ( <b>{corpus.map(c => (`${c} `))}</b>) не были удалены. Пожалуйста, попробуйте еще раз</Text>
  );
};

export const SuccessDescriptionToast = ({corpus}) => {
  return (
    <Text color={'black'} width={'350px'} fontSize={'md'}>Корпуса ( <b>{corpus.map(c => (`${c} `))}</b>) были успешно удалены</Text>
  );
};

export const LoadingDescriptionToast = ({corpus}) => {
  return (
    <Text color={'black'} width={'350px'} fontSize={'md'}>Корпуса ( <b>{corpus.map(c => (`${c} `))}</b>) удаляются и скоро не будут отображаться в списке!</Text>
  );
};
