import React, { useEffect, useState } from 'react';
import { Box, Button, createStandaloneToast, Spinner, Stack, Text, Tooltip } from '@chakra-ui/react';
import ModalCheckList from '../CheckList/ModalCheckList';
import API from '../../api';

import './corpus.css';
import {
  ErrorDescriptionToast,
  LoadingDescriptionToast,
  SuccessDescriptionToast,
} from './toastDescription';
import { useHistory } from 'react-router-dom';
import Faq from '../FAQ';
import { LoadingComponentNoWorker } from '../LoadingComponentNoWorker';

const toast = createStandaloneToast();

const Subject = {
  eng: 'english',
  deu: 'deutsch',
  fr: 'francais',
};

const variableGetCorpusList = (sub) => {
  if (sub === Subject.eng)
    return API.getEnglishTasksList;
  else if (sub === Subject.fr)
    return API.getFrancaisTasksList;
  else
    return API.getDeutschTasksList;
};

const variableDeleteCorpuses = (sub) => {
  if (sub === Subject.eng)
    return API.deleteEnglishCorpuses;
  else if (sub === Subject.fr)
    return API.deleteFrancaisCorpuses;
  else
    return API.deleteDeutschCorpuses;
};

const LanguageDelete = ({ subject }) => {

  const [allCorpusList, setAllCorpusList] = useState([]);
  const [corpuses, setCorpuses] = useState([]);
  const [checkedItems, setCheckedItems] = React.useState([]);
  const [keyCorpus, setKeyCorpus] = useState(0);

  const [isLoadingDelete, setIsLoadingDelete] = useState(false);

  const [isLoadingPageBecauseNoWorker, setIsLoadingPageBecauseNoWorker] = React.useState(false);


  const history = useHistory();

  const getCorpusListAgain = (lastTimeoutID) => {
    window.clearTimeout(lastTimeoutID);
    const getCorpusList = variableGetCorpusList(subject);
    getCorpusList().then(resp => {
      setIsLoadingPageBecauseNoWorker(false);
      setAllCorpusList(resp.data);
    }).catch(err => {
      if (err?.response?.status === 605) {
        let timeoutID = setTimeout(() => {
          getCorpusListAgain(timeoutID);
        }, 10000);
      } else {
        setIsLoadingPageBecauseNoWorker(false);
      }
    });
  };


  useEffect(() => {
    if (subject !== Subject.eng && subject !== Subject.deu && subject !== Subject.fr) {
      toast({
        title: 'Ошибка',
        description: `Невозможно удалить данный предмет (${subject}), попробуйте еще раз`,
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
      history.push('/');
    }

    variableGetCorpusList(subject)().then(resp => {
      setAllCorpusList(resp.data);
    }).catch(err => {
      if (err?.response?.status === 605) {
        setIsLoadingPageBecauseNoWorker(true);
        let timeoutID = setTimeout(() => {
          getCorpusListAgain(timeoutID);
        }, 10000);
      }
    });
  }, []); //eslint-disable-line react-hooks/exhaustive-deps

  const handleDeleteCorpuses = () => {
    const key = 'key-' + keyCorpus;
    setIsLoadingDelete(true);
    setKeyCorpus(prev => ++prev);
    toast({
      id: `${key}`,
      description: <LoadingDescriptionToast corpus={corpuses} />,
      status: 'info',
      position: 'bottom-left',
      duration: null,
      isClosable: false,
    });
    const deleteCorpuses = variableDeleteCorpuses(subject);
    deleteCorpuses({ corpus: corpuses })
      .then(resp => {
        if (toast.isActive(key)) {
          toast.update(key, {
            duration: 10,
          });
        }

        toast({
          description: <SuccessDescriptionToast corpus={corpuses} />,
          status: 'success',
          position: 'bottom-left',
          duration: null,
          isClosable: true,
        });
        variableGetCorpusList(subject)().then(resp => {
          setAllCorpusList(resp.data);
        }).catch(err => {
          if (err?.response?.status === 605) {
            setIsLoadingPageBecauseNoWorker(true);
            let timeoutID = setTimeout(() => {
              getCorpusListAgain(timeoutID);
            }, 10000);
          }
        });
        setIsLoadingDelete(false);
      })
      .catch(err => {
        if (toast.isActive(key)) {
          toast.update(key, {
            duration: 10,
          });
        }

        toast({
          description: <ErrorDescriptionToast corpus={corpuses} />,
          status: 'error',
          position: 'bottom-left',
          duration: null,
          isClosable: true,
        });
        variableGetCorpusList(subject)().then(resp => {
          setAllCorpusList(resp.data);
        }).catch(err => {
          if (err?.response?.status === 605) {
            setIsLoadingPageBecauseNoWorker(true);
            let timeoutID = setTimeout(() => {
              getCorpusListAgain(timeoutID);
            }, 10000);
          }
        });
        setIsLoadingDelete(false);
      });

    setCheckedItems(() => (new Array(allCorpusList.length).fill(false, 0, allCorpusList.length)));
    setCorpuses([]);
  };

  return (
    <>
      {isLoadingPageBecauseNoWorker ? <LoadingComponentNoWorker />
        :
        <Box width={'400px'}>
          {isLoadingDelete ?
            (<Box>
              <Spinner
                thickness='4px'
                speed='1s'
                emptyColor='gray.200'
                color='blue.500'
                size='xl'
              />
              <Tooltip
                label={'Вы можете подождать удаление корпусов, или можете перейти к другим важным делам, Мы уведомим Вас на этой странице о статусе удаления данных корпусов'}>
                <Text c fontWeight={'medium'}>Пожалуйста, подождите, идет удаление корпусов...</Text>
              </Tooltip>
            </Box>)
            : (<Box>
              <Stack spacing={4}>
                <ModalCheckList
                  setItemList={setCorpuses}
                  allItemList={allCorpusList}
                  buttonText={'Выбрать корпус'}
                  titleText={'Выберите корпус который необходимо удалить'}
                  showAll={true}
                  checkedItems={checkedItems}
                  setCheckedItems={setCheckedItems}
                  checkedAllItems={false}
                />
                {corpuses.length && corpuses.map((corpus) => {
                  let jfContent = 'center'
                  if (corpus.length > 40) {
                    jfContent = 'start';
                  }
                  return (<Box isTruncated className={'corpus'} justifyContent={jfContent} key={`${corpus}`}>{corpus}</Box>);
                })}
                <Button onClick={handleDeleteCorpuses}
                        isDisabled={corpuses.length === 0}
                >
                  Удалить выбранные корпуса
                </Button>
              </Stack>
            </Box>)
          }
          <Box position={'fixed'} right={'15%'} bottom={'50px'}>
            <Faq title={'Подсказка Delete Language'} text={'Что делать Delete Language'} />
          </Box>
        </Box>}
    </>
  );
};

export default LanguageDelete;