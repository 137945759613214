import React from 'react';
import { Stack, Text } from '@chakra-ui/react';
const text = '“ {:} “ – используется для обозначения системы уравнений (каждое уравнение необходимо выделить в круглые скобки и перечислить через запятую внутри фигурных скобок, пример: {([A]([B]x + [C]) - [B]([A]x + [C]) > [C]x),((x - [F])(x + [G]) < 0):}) '

const FaqMathUpload = () => {
  return (
    <Stack spacing={'30px'}>
      <Stack spacing={'15px'}>
        <Text fontSize='2xl' fontWeight={'bold'} mx={'auto'}>Заполнение полей в шаблоне</Text>
        <Text fontSize={'xl'} fontWeight={'semibold'}>Диапазон</Text>
        <Stack spacing={'10px'}>
          <Text fontSize={'lg'} fontWeight={'normal'}>В поле «Диапазон» указывается диапазон разброса основной переменной задачи, через которые выражаются остальные переменные.</Text>
          <Text fontSize={'lg'} fontWeight={'normal'}>В первой ячейке указывается имя переменной (вводится на латинице без пробелов, отступов, дополнительных знаков). Во второй и третьей ячейке указываются целые числа (без пробелов, отступов, дополнительных знаков), которые ограничивают диапазон разброса переменных</Text>
        </Stack>
        <Text fontSize={'xl'} fontWeight={'semibold'}>Переменные</Text>
        <Stack spacing={'10px'}>
          <Text fontSize={'lg'} fontWeight={'normal'}>В поле «Переменные» указывается формула, либо точное значение для расчета дополнительных переменных (например, если в задаче присутствует скорость велосипедиста V2, которая в два раза больше скорости велосипедиста V1, то формулу вычисления этой скорости необходимо указывать в этом поле)</Text>
          <Text fontSize={'lg'} fontWeight={'normal'}>В первой ячейке указывается имя переменной (вводится на латинице без пробелов, отступов, дополнительных знаков). Во второй ячейке указывается самое значение, либо формула расчета для этой переменной. Для указания формулы расчета переменной необходимо использовать знаки из списка ниже, также нужно между всеми знаками и цифрами необходимо ставить один символ пробел («3 + 2»), между числом и скобкой пробел не ставится</Text>
        </Stack>
        <Text fontSize={'xl'} fontWeight={'semibold'}>Ограничения</Text>
        <Stack spacing={'10px'}>
          <Text fontSize={'lg'} fontWeight={'normal'}>В поле «Ограничения» указываются накладываемые на основные и вспомогательные переменные ограничения в форме уравнений или неравенств (например ограничение t1 + t2 &lt;= 10 задает условие суммарного времени t1 и t2 не превышающего 10)</Text>
          <Text fontSize={'lg'} fontWeight={'normal'}>В ячейке указывается выражение полностью, указываются только те переменные, которые ранее были заданы в предыдущих полях, знаки используются из списка ниже, ограничения не должны противоречить друг другу, а также диапазону</Text>
        </Stack>
        <Text fontSize={'xl'} fontWeight={'semibold'}>Формула ответа</Text>
        <Stack spacing={'10px'}>
          <Text fontSize={'lg'} fontWeight={'normal'}>В поле «Формула ответа» указывается формула нахождения значения правильного ответа для задачи, если в задаче несколько ответов – необходимо создать и заполнить несколько полей, по одному на каждый ответ</Text>
          <Text fontSize={'lg'} fontWeight={'normal'}>В ячейке указывается только формула для расчета без указаний переменной, которую необходимо найти, без знаков равенства или неравенства, а также без корней и степеней, так как это может повлечь за собой двойственность ответа. Для отображения ответа в виде дроби в начале ответа необходимо написать “fraction” и ответ помещать в скобки после, пример: fraction(chisl, znam)</Text>
        </Stack>
        <Text fontSize={'xl'} fontWeight={'semibold'}>Текст шаблона</Text>
        <Stack spacing={'10px'}>
          <Text fontSize={'lg'} fontWeight={'normal'}>В поле указывается текст условия задачи с подставленными основными и вспомогательными переменными в виде названий в квадратных скобках (например: Велосипедист выехал с постоянной скоростью из города А в город В, расстояние между которыми равно [S] км. на следующий день он отправился обратно в А, увеличив скорость на [dV] км/ч. по пути он сделал остановку на [t] ((t, час, часа, часов)), в результате чего затратил на обратный путь столько же времени, сколько на путь из А в В. Найдите скорость велосипедиста на пути из А в В.)</Text>
          <Text fontSize={'lg'} fontWeight={'normal'}>В тексте шаблона необходимо написать само условие, указать искомое значение либо действие, а также уравнение или неравенство (при наличии). При записи уравнений или неравенств необходимо пользоваться стандартными математическими знаками, кроме знаков возведения в степень, для этого используется символ «^»</Text>
        </Stack>
      </Stack>
      <Stack spacing={'15px'}>
        <Text fontSize='2xl' fontWeight={'bold'} mx={'auto'}>Заполнение полей в шаблоне</Text>
        <Stack spacing={'10px'}>
          <Text fontSize={'lg'} fontWeight={'normal'}>“ - “ – вычитание</Text>
          <Text fontSize={'lg'} fontWeight={'normal'}>“ + “ – сложение</Text>
          <Text fontSize={'lg'} fontWeight={'normal'}>“ / “ – деление</Text>
          <Text fontSize={'lg'} fontWeight={'normal'}>“ * “ – умножение</Text>
          <Text fontSize={'lg'} fontWeight={'normal'}>“ > “ – больше</Text>
          <Text fontSize={'lg'} fontWeight={'normal'}>“ &lt; “ – меньше</Text>
          <Text fontSize={'lg'} fontWeight={'normal'}>“ >= “ – больше или равно</Text>
          <Text fontSize={'lg'} fontWeight={'normal'}>“ &lt;= “ – меньше или равно</Text>
          <Text fontSize={'lg'} fontWeight={'normal'}>“ != “ – не равно</Text>
          <Text fontSize={'lg'} fontWeight={'normal'}>“ == “ – равно </Text>
          <Text fontSize={'lg'} fontWeight={'normal'}>“ ** “ – возведение в степень</Text>
          <Text fontSize={'lg'} fontWeight={'normal'}>“ sqrt “ – квадратный корень из числа</Text>
          <Text fontSize={'lg'} fontWeight={'normal'}>“ % “ – нахождение остатка от деления</Text>
          <Text fontSize={'lg'} fontWeight={'normal'}>“ // “ – деление без остатка</Text>
          <Text fontSize={'lg'} fontWeight={'normal'}>{text}</Text>
        </Stack>
      </Stack>
    </Stack>
  );
};

export default FaqMathUpload;