import React, { useState, useEffect } from 'react';
import { Button, Box, Text, Select, Flex, Input, createStandaloneToast } from '@chakra-ui/react';
import API from '../api';
import Faq from './FAQ';
import { LoadingComponentNoWorker } from './LoadingComponentNoWorker';
import { LoadingDescriptionToast } from './LanguageUploadForm/toastDescription';

const toast = createStandaloneToast();

const variableGetTaskList = (sub) => {
  if (sub === 'math')
    return API.getMathTasksList;
  else if (sub === 'essay')
    return API.getEssayTasksList;
  else
    return API.getPhysicsTasksList;
};

const variableGetSubnumbers = (sub) => {
  if (sub === 'math')
    return API.getMathSubnumbers;
  else if (sub === 'essay')
    return API.getEssaySubNumbers;
  else
    return API.getPhysicsSubnumbers;
};

const variableGetNewTheme= (sub) => {
  if (sub === 'math')
    return API.newMathTheme;
  else if (sub === 'essay')
    return API.newEssayTheme;
  else
    return API.newPhysicsTheme;
};

const CheckWelcomeScreen = ({
                              subject,
                              setSubject,
                              task,
                              setTask,
                              onSubmit,
                              subnumber,
                              setSubnumber,
                              buttonText,
                            }) => {
  const [taskList, setTaskList] = useState([]);
  const [subnumbersList, setSubnumbersList] = useState([]);
  const [isLoadingPageBecauseNoWorker, setIsLoadingPageBecauseNoWorker] = useState(false);
  const [name, setName] = useState('');
  const [refresh, setRefresh] = useState(false);

  useEffect(() => {
    setSubject(() => 'math');
    setTask('');
    setSubnumber('');
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const getExactScienceTasksListAgain = (lastTimeoutID) => {
    window.clearTimeout(lastTimeoutID);
    const getTasksList = variableGetTaskList(subject);
    getTasksList().then(resp => {
      setIsLoadingPageBecauseNoWorker(false);
      setTaskList(() => {
        return [ {'createNew': 'Создать новую тему'}, ...Object.keys(resp.body).map((key) => {return {[key]: resp.body[key]}})];
      })
    }).catch(err => {
      if (err?.response?.status === 605) {
        let timeoutID = setTimeout(() => {
          getExactScienceTasksListAgain(timeoutID);
        }, 10000);
      }
      else{
        setIsLoadingPageBecauseNoWorker(false);
      }
    });
  }

  const getExactScienceSubnumbersAgain = (lastTimeoutID) => {
    window.clearTimeout(lastTimeoutID);
    const getSubnumbers = variableGetSubnumbers(subject);
    getSubnumbers({task}).then(resp => {
      setIsLoadingPageBecauseNoWorker(false);
      setSubnumbersList(resp.data);
    }).catch(err => {
      if (err?.response?.status === 605) {
        let timeoutID = setTimeout(() => {
          getExactScienceTasksListAgain(timeoutID);
        }, 10000);
      }
      else{
        setIsLoadingPageBecauseNoWorker(false);
      }
    });
  }

  useEffect(() => {
    if (subject === 'math' || subject === 'physics' || subject === 'essay') {
      const getTasksList = variableGetTaskList(subject);
      getTasksList().then(resp => {
        setTaskList(() => {
          return [ {'createNew': 'Создать новую тему'}, ...Object.keys(resp.body).map((key) => {return {[key]: resp.body[key]}}).sort((a, b) => {
            if (Object.values(a)[0].toLowerCase() < Object.values(b)[0].toLowerCase()) {
              return -1;
            }
            if (Object.values(a)[0].toLowerCase() > Object.values(b)[0].toLowerCase()) {
              return 1;
            }
            // a должно быть равным b
            return 0;
          })];
        })
      }).catch(err => {
        if (err?.response?.status === 605) {
          setIsLoadingPageBecauseNoWorker(true);
          let timeoutID = setTimeout(() => {
            getExactScienceTasksListAgain(timeoutID);
          }, 10000);
        }
      });
    }
  }, [subject, refresh]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    setName('');
    setSubnumber('');
  }, [task])

  useEffect(() => {
    if (task && (subject === 'math' || subject === 'physics' || subject === 'essay')) {
      const getSubnumbers = variableGetSubnumbers(subject);
      getSubnumbers({ task }).then(resp => {
        setSubnumbersList(resp.data);
      }).catch(err => {
        if (err?.response?.status === 605) {
          setIsLoadingPageBecauseNoWorker(true);
          let timeoutID = setTimeout(() => {
            getExactScienceSubnumbersAgain(timeoutID);
          }, 10000);
        }
      });
    }
  }, [task, subject]); // eslint-disable-line react-hooks/exhaustive-deps

  const handleSubnumberChange = e => {
    const newSelection = e.target.value;
    if (newSelection === 'new') {
      setSubnumber('new');
    } else {
      setSubnumber(newSelection);
    }
  };

  const handleSubmit = () => {
    if (task === 'createNew') {
      const key = `${Date.now()}`;

      toast({
        id: `${key}`,
        description: `Тема '${name}' по предмету ${subject} создается`,
        status: 'info',
        position: 'bottom-left',
        duration: null,
        isClosable: false,
      });

      const newTheme = variableGetNewTheme(subject);
      newTheme({name}).then(() => {
        if (toast.isActive(key)) {
          toast.update(key, {
            duration: 10,
          });
        }
        toast({
          title: 'Выполнено',
          description: `Тема '${name}' по предмету ${subject} была создана`,
          status: 'success',
          position: 'bottom-left',
          duration: 3000,
          isClosable: true,
        });
        setRefresh(prev => !prev);
        setTask('');
      })
        .catch(() => {
          if (toast.isActive(key)) {
            toast.update(key, {
              duration: 10,
            });
          }

          toast({
            title: 'Ошибка',
            description: `Не удалось создать тему ${name} по предмету ${subject}`,
            status: 'error',
            position: 'bottom-left',
            duration: 3000,
            isClosable: true,
          });
        })
    }
    else {
      onSubmit();
    }
  }

  return (
    <>
      {isLoadingPageBecauseNoWorker ? <LoadingComponentNoWorker />
        :
        <Box mr='auto' ml='auto'>
          <Select width='400px' mr='auto' ml='auto' mb='40px'
                  onChange={e => {
                    setSubject(e.target.value);
                  }}
                  value={subject}
          >
            <option value='math'>Математика</option>
            <option value='essay'>Изложение</option>
            <option value='physics'>Физика</option>
            <option value='english'>Английский язык</option>
            <option value='deutsch'>Немецкий язык</option>
            <option value='francais'>Французский язык</option>
          </Select>
          {(subject === 'math' || subject === 'physics' || subject === 'essay') ? <Box>
              <Flex>
                <Text mr='16px'>Задание:</Text>
                {taskList && taskList.length && (
                  <Select
                    onChange={e => {
                      setTask(e.target.value);
                    }}
                    value={task}
                    placeholder='Выберите задание'
                  >
                    {taskList.map((task, id) => {
                      if (typeof task === 'object') {
                        return (
                          <option value={Object.keys(task)[0]} key={`task-option-${id}`}>
                            {Object.values(task)[0]}
                          </option>
                        )
                      }
                    })}
                  </Select>
                )}
              </Flex>
              {task === 'createNew' ?
                <Input mt={'30px'} placeholder={'Введите название темы'}
                       value={name}
                       onChange={(e) => setName(e.target.value)}
                />
                : <Flex mt='40px'>
                <Text mr='16px'>Шаблон:</Text>
                {taskList && taskList.length && (
                  <Flex width='100%'>
                    <Select
                      onChange={handleSubnumberChange}
                      value={subnumber}
                      placeholder='Выберите шаблон'
                      isDisabled={!task}
                    >
                      <option value='new'>создать новый шаблон</option>

                      {subnumbersList.map((opt, id) => (
                        <option value={opt} key={`task-option-${id}`}>
                          {opt}
                        </option>
                      ))}
                    </Select>
                  </Flex>
                )}
              </Flex>}
              <Button
                onClick={handleSubmit}
                disabled={(!subnumber && name === '')}
                mt='40px'
                variant='solid'
              >
                {task === 'createNew' ? 'Создать новую тему' : buttonText}
              </Button>
            </Box>
            :
            <Button
              onClick={onSubmit}
              mt='40px'
              variant='solid'
            >
              {buttonText}
            </Button>
          }
          <Box position={'fixed'} right={'15%'} bottom={'50px'}>
            <Faq title={'Подсказка Upload'} text={'Что делать Upload'} />
          </Box>
        </Box>}
    </>
  );
};

export default CheckWelcomeScreen;
